<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Employee Form</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div class="">
          <div id="input" class="">
            <div class="">
              <div class="intro-y box p-5">

                <h3 class="font-bold text-sm pb-2">Personal Details</h3>
                <div class="md:grid grid-cols-2 gap-6">
                  <div>
                    <div class="md:grid grid-cols-3 gap-2">

                      <div class="mt-2">
                        <div>
                          <label for="regular-form-2" class="form-label mb-0">First Name</label>

                          <input v-model="formData.first_name" id="regular-form-1" type="text" class="form-control"
                            placeholder="First Name" />
                        </div>
                        <span class="text-primary-3" v-if="pagedata.errors.first_name">{{ pagedata.errors.first_name[0]
                        }}</span>
                      </div>
                      <div class="mt-2">
                        <div>
                          <label for="regular-form-2" class="form-label mb-0">Last Name</label>

                          <input v-model="formData.last_name" id="regular-form-1" type="text" class="form-control"
                            placeholder="Last Name" />
                        </div>
                        <span class="text-primary-3" v-if="pagedata.errors.last_name">{{ pagedata.errors.last_name[0]
                        }}</span>
                      </div>
                      <div class="mt-2">
                        <div>
                          <label for="regular-form-2" class="form-label mb-0">Short Code</label>

                          <input v-model="formData.short_name" id="regular-form-1" type="text" class="form-control" disabled
                            placeholder="Short Name" />
                        </div>
                      </div>
                    </div>
                    <div class="md:grid grid-cols-2 gap-2">

                      <div class="mt-2">
                        <div>
                          <label for="regular-form-2" class="form-label mb-0">Email Id</label>

                          <input v-model="formData.email" id="regular-form-1" type="text" class="form-control"
                            placeholder="Email Id" />
                        </div>
                        <span class="text-primary-3" v-if="pagedata.errors.email">{{ pagedata.errors.email[0]
                        }}</span>
                      </div>
                      <div class="mt-2">
                        <div>
                          <label for="regular-form-2" class="form-label mb-0">Mobile No</label>

                          <input v-model="formData.mobile_no" id="regular-form-1" type="text" class="form-control"
                            placeholder="Mobile No" />
                        </div>
                        <span class="text-primary-3" v-if="pagedata.errors.mobile_no">{{ pagedata.errors.mobile_no[0]
                        }}</span>
                      </div>
                    </div>
                    <div class="md:grid grid-cols-3 gap-2">

                      <div class="mt-2">
                        <div>

                          <label for="date-picker" class="form-label mb-0">Birth Date</label>
                          <div class="relative">
                            <input v-model="formData.bdate" id="regular-form-1" type="date" class="form-control"
                            placeholder="Date" />
                            <!-- <Litepicker v-model="formData.bdate" format= "YYYY-MM-DD" :options="{
                              autoApply: true,
                              showWeekNumbers: true,
                              dropdowns: {
                                minYear: 1950,
                                maxYear: 2010,
                                months: true,
                                years: true
                              }
                            }" class="form-control pl-12" id="date-picker" /> -->
                          </div>
                        </div>
                      </div>
                      <div class="mt-2">
                        <div>
                          <label for="regular-form-2" class="form-label mb-0">Gender</label>
                          <select
                            class="form-select"
                            v-model="formData.gender"
                            aria-label="Select Gender"
                          >
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                          </select>

                        </div>
                      </div>
                      <div class="mt-2">
                        <label for="regular-form-2" class="form-label mb-0">Age</label>
                        <input v-model="formData.age" id="regular-form-1" type="text" class="form-control"
                          placeholder="Age" />
                      </div>
                      <div class="mt-2">
                        <label for="regular-form-2" class="form-label mb-0">Blood Group</label>
                        <input v-model="formData.blood_group" id="regular-form-1" type="text" class="form-control"
                          placeholder="Blood Group" />
                      </div>
                      <div class="mt-2">
                        <label for="regular-form-2" class="form-label mb-0">Cast</label>
                        <input v-model="formData.cast" id="regular-form-1" type="text" class="form-control"
                          placeholder="Cast" />
                      </div>
                      <div class="mt-2">
                        <div>
                          <label for="regular-form-2" class="form-label mb-0">Merried Status</label>
                          <select
                            class="form-select"
                            v-model="formData.material"
                            aria-label="Merried Status"
                          >
                            <option value="1">Merried</option>
                            <option value="2">Unmerried</option>
                          </select>

                        </div>
                      </div>

                    </div>
                    <div class="md:grid grid-cols-2 gap-2">
                      <div class="mt-1">
                        <label for="regular-form-4" class="form-label mb-0">Aadhar No</label>
                        <input type="text" class="form-control" v-model="formData.aadhar_no" placeholder="Aadhar No"
                          aria-describedby="input-group-3" />
                      </div>
                      <div class="mt-1">
                        <label for="regular-form-4" class="form-label mb-0">Pan No</label>
                        <input type="text" class="form-control" v-model="formData.pan_no" placeholder="Pan No"
                          aria-describedby="input-group-3" />
                      </div>
                    </div>

                  </div>
                  <div>
                    <div class="md:grid grid-cols-1 gap-2 pl-5 border-l">
                      <div class="mt-2">
                        <div>
                          <label class="form-label mb-0">Select Manager</label>
                          <TomSelect v-model="formData.manager_id" @change="getProcesses" :options="{
                            placeholder: 'Select your favorite actors'
                          }" class="w-full">
                            <option value="0">Select Manager</option>
                            <option v-for="dept in pagedata.managers" :key="dept.id" :value="dept.id">{{ dept.first_name + ' ' +
                              dept.last_name }}</option>
                          </TomSelect>

                        </div>
                        <span class="text-primary-3" v-if="pagedata.errors.manager_id">{{ pagedata.errors.manager_id[0] }}</span>
                      </div>
                    </div>
                    <div class="md:grid grid-cols-1 gap-2 pl-5 border-l">
                      <div class="mt-2">
                        <div>
                          <label class="form-label mb-0">Process</label>
                          <div class="input-group">
                            <!-- <div id="input-group-3" class="input-group-text">Process</div> -->
                            <TomSelect v-model="formData.process" :options="{
                              placeholder: 'Select your favorite actors'
                            }" class="w-full" multiple>
                              <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{
                                process.process_name }}</option>
                            </TomSelect>
                          </div>
                          <div class="text-primary-3" v-if="pagedata.errors.process">{{ pagedata.errors.process[0] }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="md:grid grid-cols-1 gap-2 border-l pl-5">
                      <hr class="mt-7">
                      <h3 class="font-bold text-sm pb-2">Account Details</h3>
                      <div class="md:grid grid-cols-3 gap-2">

                        <div class="mt-2">
                          <label class="form-label mb-0">Bank Name</label>
                          <input type="text" class="form-control" v-model="formData.bank_name" placeholder="Bank Name"
                            aria-describedby="input-group-3" />
                        </div>
                        <div class="mt-2">
                          <label class="form-label mb-0">IFSC Code</label>
                          <input type="text" class="form-control" v-model="formData.ifsc_code" placeholder="IFSC Code"
                            aria-describedby="input-group-3" />
                        </div>
                        <div class="mt-2">
                          <label class="form-label mb-0">Account No</label>
                          <input type="text" class="form-control" v-model="formData.account_no" placeholder="Account No"
                            aria-describedby="input-group-3" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="intro-y box p-5 mt-5">
                <div class="md:grid grid-cols-2 gap-6">
                  <div class="">

                    <h3 class="font-bold text-sm pb-2">Adress Details</h3>
                    <div class="mt-2">
                      <label for="regular-form-4" class="form-label mb-0">Address</label>
                      <textarea id="regular-form-4" type="password" class="form-control" v-model="formData.addr_current"
                        placeholder="Current Address"></textarea>
                    </div>
                    <div class="mt-2">
                      <label for="regular-form-5" class="form-label mb-0">Village Address</label>
                      <textarea id="regular-form-5" type="text" v-model="formData.addr_permant" class="form-control"
                        placeholder="Permant Address"></textarea>
                    </div>
                    <div class="md:grid grid-cols-2 gap-2">
                      <div class="mt-2">
                        <label for="regular-form-4" class="form-label mb-0">ZipCode</label>
                        <input type="text" class="form-control" v-model="formData.pin" placeholder="ZipCode"
                          aria-describedby="input-group-3" />
                      </div>
                      <div class="mt-2">
                        <label for="regular-form-4" class="form-label mb-0">Taluka</label>
                        <input type="text" class="form-control" v-model="formData.taluka" placeholder="Taluka"
                          aria-describedby="input-group-3" />
                      </div>
                      <div class="mt-2">
                        <label for="regular-form-4" class="form-label mb-0">District</label>
                        <input type="text" class="form-control" v-model="formData.discrict" placeholder="District"
                          aria-describedby="input-group-3" />
                      </div>
                      <div class="mt-2">
                        <label for="regular-form-4" class="form-label mb-0">State</label>
                        <input type="text" class="form-control" v-model="formData.state" placeholder="State"
                          aria-describedby="input-group-3" />
                      </div>
                    </div>
                  </div>
                  <div class="border-l pl-5">
                    <h3 class="font-bold text-sm pb-2">Student Details</h3>
                    <div class="md:grid grid-cols-2 gap-2">
                      <div class="mt-1">
                        <label for="regular-form-4" class="form-label mb-0">Standard</label>
                        <input type="text" class="form-control" v-model="formData.standard" placeholder="Standard"
                          aria-describedby="input-group-3" />
                      </div>
                      <div class="mt-1">
                        <label for="regular-form-4" class="form-label mb-0">College</label>
                        <input type="text" class="form-control" v-model="formData.college" placeholder="College"
                          aria-describedby="input-group-3" />
                      </div>
                      <div class="mt-1">
                        <label for="regular-form-4" class="form-label mb-0">Per (%)</label>
                        <input type="text" class="form-control" v-model="formData.percent" placeholder="Per (%)"
                          aria-describedby="input-group-3" />
                      </div>
                      <div class="mt-1">
                        <label for="regular-form-4" class="form-label mb-0">Language</label>
                        <input type="text" class="form-control" v-model="formData.language" placeholder="Language"
                          aria-describedby="input-group-3" />
                      </div>
                    </div>
                    <hr class="mt-3 mb-2">
                    <h3 class="font-bold text-sm pb-2">Manager Notes</h3>
                    <div class="md:grid grid-cols-1 gap-2">
                      <div class="mt-2">
                        <label for="regular-form-4" class="form-label mb-0">Shape Know</label>
                        <input type="text" class="form-control" v-model="formData.shape_know" placeholder="Shape Know"
                          aria-describedby="input-group-3" />
                      </div>
                    </div>
                    <div class="md:grid grid-cols-2 gap-2">
                      <div class="mt-2">
                        <label for="regular-form-4" class="form-label mb-0">Exp. Salary</label>
                        <input type="text" class="form-control" v-model="formData.salary" placeholder="Exp. Salary"
                          aria-describedby="input-group-3" />
                      </div>
                      <div class="mt-2">
                        <label for="regular-form-4" class="form-label mb-0">Domain Know</label>
                        <input type="text" class="form-control" v-model="formData.domain_know" placeholder="Domain Know"
                          aria-describedby="input-group-3" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="intro-y p-5 box mt-5">

                <h3 class="font-bold text-sm">Diamond expirence</h3>
                <div class="md:grid grid-cols-2 gap-2 mt-3">
                  <div class="flex items-center gap-2">
                    <label for="regular-form-4" class="form-label mb-0">Diamond Knowledge : </label>
                    <div class="flex items-center">
                      <input
                        class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 cursor-pointer"
                        value="0" type="radio" name="diamond_knowledge" id="diamond_knowledge_yes" v-model="formData.diamond_knowledge">
                      <label for="diamond_knowledge_yes" class="ml-2">Yes</label>
                    </div>
                    <div class="flex items-center">
                      <input
                        class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 cursor-pointer"
                        value="1" type="radio" name="diamond_knowledge" id="diamond_knowledge_no" v-model="formData.diamond_knowledge">
                      <label for="diamond_knowledge_no" class="ml-2">No</label>
                    </div>
                  </div>
                  <div class="flex items-center gap-2">
                    <label for="regular-form-4" class="form-label mb-0">Sarin Knowledge : </label>
                    <div class="flex items-center">
                      <input
                        class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 cursor-pointer"
                        value="0" type="radio" name="sarin_knowledge" id="sarin_knowledge_yes" v-model="formData.sarin_knowledge">
                      <label for="sarin_knowledge_yes" class="ml-2">Yes</label>
                    </div>
                    <div class="flex items-center">
                      <input
                        class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 cursor-pointer"
                        value="1" type="radio" name="sarin_knowledge" id="sarin_knowledge_no" v-model="formData.sarin_knowledge">
                      <label for="sarin_knowledge_no" class="ml-2">No</label>
                    </div>
                  </div>
                  <div class="flex items-center gap-2">
                    <label for="regular-form-4" class="form-label mb-0">Laser Knowledge : </label>
                    <div class="flex items-center">
                      <input
                        class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 cursor-pointer"
                        value="0" type="radio" name="laser_knowledge" id="laser_knowledge_yes" v-model="formData.laser_knowledge">
                      <label for="laser_knowledge_yes" class="ml-2">Yes</label>
                    </div>
                    <div class="flex items-center">
                      <input
                        class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 cursor-pointer"
                        value="1" type="radio" name="laser_knowledge" id="laser_knowledge_no" v-model="formData.laser_knowledge">
                      <label for="laser_knowledge_no" class="ml-2">No</label>
                    </div>
                  </div>
                  <div class="flex items-center gap-2">
                    <label for="regular-form-4" class="form-label mb-0">Computer Knowledge : </label>
                    <div class="flex items-center">
                      <input
                        class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 cursor-pointer"
                        value="0" type="radio" name="computer_knowledge" id="computer_knowledge_yes" v-model="formData.computer_knowledge">
                      <label for="computer_knowledge_yes" class="ml-2">Yes</label>
                    </div>
                    <div class="flex items-center">
                      <input
                        class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 cursor-pointer"
                        value="1" type="radio" name="computer_knowledge" id="computer_knowledge_no" v-model="formData.computer_knowledge">
                      <label for="computer_knowledge_no" class="ml-2">No</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="intro-y p-5 box mt-5">
                <h3 class="font-bold text-sm">Reference Details</h3>
                <div class="md:grid grid-cols-3 gap-2">
                  <div class="mt-3">
                    <label for="regular-form-4" class="form-label mb-0">Name</label>
                    <input type="text" class="form-control" v-model="formData.ref_name" placeholder="Salary"
                      aria-describedby="input-group-3" />
                  </div>
                  <div class="mt-3">
                    <label for="regular-form-4" class="form-label mb-0">Code</label>
                    <input type="text" class="form-control" v-model="formData.ref_code" placeholder="Salary"
                      aria-describedby="input-group-3" />
                  </div>
                  <div class="mt-3">
                    <label for="regular-form-4" class="form-label mb-0">Designation</label>
                    <input id="regular-form-2" type="tel" v-model="formData.ref_designation" class="form-control form-control"
                      placeholder="" />
                  </div>
                  <div class="mt-3">
                    <label for="regular-form-4" class="form-label mb-0">Mobile No</label>
                    <input id="regular-form-2" type="text" v-model="formData.ref_mobile" class="form-control form-control"
                      placeholder="" />
                  </div>
                  <div class="mt-3">
                    <label for="regular-form-4" class="form-label mb-0">Address</label>
                    <input id="regular-form-2" type="text" v-model="formData.ref_address" class="form-control form-control"
                      placeholder="" />
                  </div>
                  <div class="mt-3">
                    <label for="regular-form-4" class="form-label mb-0">About Company</label>
                    <input id="regular-form-2" type="text" v-model="formData.about_company" class="form-control form-control"
                      placeholder="" />
                  </div>
                </div>
              </div>
              <div class="text-left mt-5">
                <router-link :to="{ name: 'employee-details' }" class="btn btn-outline-secondary w-24 mr-1">
                  Cancel
                </router-link>
                <button type="button" @click="SaveEmployee" class="btn btn-primary w-24">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import moment from 'moment'
import { stringify } from 'json5'
export default defineComponent({
  setup() {
    const pagedata = reactive({
      processes: [],
      managers: [],
      errors: [],
      error: ''
    })
    const route = useRoute()
    const formData = reactive({
      id: '',
      first_name: '',
      last_name: '',
      mobile_no: '',
      short_name: '',
      email: '',
      bdate: '',
      material: 1,
      addr_current: '',
      addr_permant: '',
      city: '',
      state: '',
      pin: '',
      pan_no: '',
      aadhar_no: '',
      limit: '',
      fixed: false,
      processper: true,
      user_role: 7,
      user_type: 2,
      process: [],
      manager_id: '0',
      salary: '',
      gender: 1,
      blood_group: '',
      age: '',
      cast: '',
      standard: '',
      college: '',
      percent: '',
      language: '',
      bank_name: '',
      ifsc_code: '',
      account_no: '',
      discrict: '',
      taluka: '',
      shape_know: '',
      domain_know: '',
      ref_name: '',
      ref_code: '',
      ref_designation: '',
      ref_mobile: '',
      ref_address: '',
      about_company: '',
      diamond_knowledge: '1',
      sarin_knowledge: '1',
      laser_knowledge: '1',
      computer_knowledge: '1',
    })
    function subtractYears(date, years) {
      // 👇 make copy with "Date" constructor
      const dateCopy = new Date(date);

      dateCopy.setFullYear(date.getFullYear() - years);
      dateCopy.setDate(1);
      dateCopy.setMonth(1);

      return dateCopy;
    }
    onMounted(() => {
      const date = new Date();

      formData.bdate = moment(subtractYears(date, 18)).format('YYYY-MM-DD');
      getManagers()
      if (route.params.id) {
        formData.id = route.params.id
        getEmployeeDetails()
      } else {
        getLatestShortName()
      }
    })
    function getManagers() {
      axios({
        url: 'api/master/dp_managers',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept': 'application/json' } }
      }).then((res) => {
        pagedata.managers = res.data.items
      })
    }
    function getProcesses() {
      if (formData.manager_id == '0') {
        formData.process = []
      } else {

        const params = {
          manager_id: formData.manager_id
        }
        // formData.process = []
        axios({
          url: 'api/master/get_process_by_manager',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept': 'application/json' } }
        }).then((res) => {
          pagedata.processes = res.data.items
        })
      }

    }
    function SaveEmployee() {
      formData.bdate = moment(formData.bdate).format('YYYY-MM-DD');
      if (route.params.id) {
        axios({
          url: 'api/master/update_user_details',
          method: 'post',
          baseURL: BASE_URL,
          data: formData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept': 'application/json' } }
        }).then(() => {
          router.push({ name: 'employee-details' })
        }).catch((err) => {
          if (err.response.data.error) {
            pagedata.error = err.response.data.error
            swal("Warning!", pagedata.error, "warning");
          } else {
            pagedata.errors = err.response.data.errors
            setTimeout(() => {
              pagedata.errors = []
            }, 3000);
          }
        })
      } else {
        axios({
          url: 'api/master/add_new_user',
          method: 'post',
          baseURL: BASE_URL,
          data: formData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept': 'application/json' } }
        }).then(() => {
          router.push({ name: 'employee-details' })
        }).catch((err) => {
          if (err.response.data.error) {
            pagedata.error = err.response.data.error
            swal("Warning!", pagedata.error, "warning");
          } else {
            pagedata.errors = err.response.data.errors
            setTimeout(() => {
              pagedata.errors = []
            }, 3000);
          }
        })
      }
    }
    function getEmployeeDetails() {
      const params = {
        id: route.params.id, user_type: formData.user_type
      }
      axios({
        url: 'api/master/show_user_details',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept': 'application/json' } }
      }).then((res) => {
        formData.first_name = (res.data.item.first_name ? res.data.item.first_name : "")
        formData.last_name = (res.data.item.last_name ? res.data.item.last_name : "")
        formData.short_name = (res.data.item.short_name ? res.data.item.short_name : "")
        formData.limit = (res.data.item.limit ? res.data.item.limit : "")
        formData.email = (res.data.item.email ? res.data.item.email : "")
        formData.mobile_no = (res.data.item.mobile_no ? res.data.item.mobile_no : "")
        formData.bdate = (res.data.item.dob ? res.data.item.dob : "")
        formData.gender = (res.data.item.gender ? res.data.item.gender : "")
        formData.blood_group = (res.data.item.blood_group ? res.data.item.blood_group : "")
        formData.pan_no = (res.data.item.pan_no ? res.data.item.pan_no : "")
        formData.aadhar_no = (res.data.item.aadhar_no ? res.data.item.aadhar_no : "")
        formData.age = (res.data.item.age ? res.data.item.age : "")
        formData.material = (res.data.item.merital_status ? res.data.item.merital_status : "")
        formData.cast = (res.data.item.cast ? res.data.item.cast : "")
        formData.user_role = (res.data.item.user_role ? res.data.item.user_role : "")
        formData.user_type = (res.data.item.user_type ? res.data.item.user_type : "")
        formData.process = (res.data.item.process == null ? [] : res.data.item.process)
        formData.fixed = (res.data.item.salary_fixed ? true : false)
        formData.manager_id = (res.data.item.manager_id ? String(res.data.item.manager_id) : "")
        formData.standard = (res.data.item.standard ? res.data.item.standard : "")
        formData.college = (res.data.item.college ? res.data.item.college : "")
        formData.percent = (res.data.item.percent ? res.data.item.percent : "")
        formData.language = (res.data.item.language ? res.data.item.language : "")
        formData.bank_name = (res.data.item.bank_name ? res.data.item.bank_name : "")
        formData.ifsc_code = (res.data.item.ifsc_code ? res.data.item.ifsc_code : "")
        formData.account_no = (res.data.item.account_no ? res.data.item.account_no : "")
        formData.addr_current = (res.data.item.current_addr ? res.data.item.current_addr : "")
        formData.addr_permant = (res.data.item.permenamt_addr ? res.data.item.permenamt_addr : "")
        formData.discrict = (res.data.item.discrict ? res.data.item.discrict : "")
        formData.taluka = (res.data.item.taluka ? res.data.item.taluka : "")
        formData.city = (res.data.item.city ? res.data.item.city : "")
        formData.state = (res.data.item.state ? res.data.item.state : "")
        formData.pin = (res.data.item.pincode ? res.data.item.pincode : "")
        formData.shape_know = (res.data.item.shape_know ? res.data.item.shape_know : "")
        formData.salary = (res.data.item.salary ? res.data.item.salary : "")
        formData.domain_know = (res.data.item.domain_know ? res.data.item.domain_know : "")
        formData.ref_name = (res.data.item.ref_name ? res.data.item.ref_name : "")
        formData.ref_code = (res.data.item.ref_code ? res.data.item.ref_code : "")
        formData.ref_designation = (res.data.item.ref_designation ? res.data.item.ref_designation : "")
        formData.ref_mobile = (res.data.item.ref_mobile ? res.data.item.ref_mobile : "")
        formData.ref_address = (res.data.item.ref_address ? res.data.item.ref_address : "")
        formData.about_company = (res.data.item.about_company ? res.data.item.about_company : "")
        formData.diamond_knowledge = (String(res.data.item.diamond_knowledge))
        formData.sarin_knowledge = (String(res.data.item.sarin_knowledge))
        formData.laser_knowledge = (String(res.data.item.laser_knowledge))
        formData.computer_knowledge = (String(res.data.item.computer_knowledge))
      })

    }
    function getLatestShortName() {
      const params = {
      }
      axios({
        url: 'api/master/last_employee_code',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept': 'application/json' } }
      }).then((res) => {
        formData.short_name = res.data.short_name
      })

    }
    watch(() => formData.fixed, (CurrentVal, OldVal) => {
      formData.processper = !formData.fixed
    })
    watch(() => formData.processper, (CurrentVal, OldVal) => {
      formData.fixed = !formData.processper
    })
    return {
      formData,
      pagedata,
      SaveEmployee,
      getProcesses
    }
  }
})
</script>
