<template>
  <!-- BEGIN: Profile Info -->
  <div id="basic-non-sticky-notification-packet-success" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-4" v-if="page_data.message">{{ page_data.message }}</div>
  </div>
  <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
      <div class="font-medium text-primary-3" v-if="page_data.error">{{ page_data.error }}</div>
  </div>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center">
        <h2 class="text-lg font-medium mr-auto">Planning Stock</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
            <div class="flex items-center mt-2 ml-2">
                <div class="mr-2 flex items-center">
                    <input id="remember-me" type="checkbox" v-model="page_data.check" class="form-check-input border mr-2 bg-white" />
                    <label for="remember-me" class="text-md font-medium">Manual</label>
                </div>
            </div>
            <div class="w-full flex sm:w-auto mt-3 ml-2">
                <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
                    <input type="text" v-model="page_data.search" @change="getPacketDetailsScan" autofocus ref="focusMe" class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Scan Packet" />
                    <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
                </div>
                <button class="btn btn-primary shadow-md mr-2" @click="checkadd">Add</button>
            </div>
        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div>
            <div class="grid grid-cols-12 gap-5">
                <!-- <div class="col-span-12 md:col-span-12 lg:col-span-3 xl:col-span-2">
                        <h2 class="text-lg font-bold">Available Packets</h2>
                    </div> -->
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                    <div>
                        <input id="tabulator-html-filter-value" v-model="filter_data.stock_no" type="text" class="form-control 2xl:w-full" placeholder="Stock No" />
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                    <div>
                        <TomSelect v-model="filter_data.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Kapan'
                    }
                  }
                }" class="w-full">
                            <option value="0">Select Kapan</option>
                            <option v-for="(item, index) in page_data.kapan_details" :key="index" :value="item.id">{{item.lot_no}} ({{ item.voucher_no }})</option>
                        </TomSelect>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                  <div>
                    <TomSelect v-model="filter_data.packet_status" :options="{
                      placeholder: 'Select Packet Status',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Packet Type'
                        }
                      }
                    }" class="w-full">
                            <option value="0">Packet Status</option>
                            <option value="pending_plan">Pending Plan</option>
                            <option value="added_plan">Added Plan</option>
                            <option value="final_plan">Final Plan</option>
                            <option value="ls_done">LS Done Plan</option>
                          </TomSelect>
                        </div>
                      </div>
                  <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2" v-if="[1,2,8].includes(user_details.user_role)">
                      <div>
                          <TomSelect v-model="filter_data.party_id" :options="{
                    placeholder: 'Select Party',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Party'
                      }
                    }
                  }" class="w-full">
                              <option value="0">Select OutParty</option>
                              <option v-for="(item, index) in page_data.party_details" :key="index" :value="item.id">{{ item.firm_name }}</option>
                          </TomSelect>
                      </div>
                  </div>
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                    <div>
                        <button class="btn btn-secondary mr-2" @click="resetFilters">
                            Reset
                        </button>
                        <button class="btn btn-primary " @click="initTabulator">
                            Generate
                        </button>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-3">
                    <div class="flex flex-wrap">

                        <div class="text-sm font-bold mr-4">Total Stock: {{ page_data.total.total_packets ? page_data.total.total_packets : 0 }}</div>
                        <!-- <div class="text-sm font-bold">Total WT(Ct): {{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) : 0 }} Ct</div> -->
                    </div>
                </div>
            </div>
        </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <div id="new-manual-planning" ref="new-manual-planning" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Add Planning Details</h2>
          </div>
          <div class="modal-body">


            <table class="w-full border">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Mk Wt</th>
                  <th>PL Wt</th>
                  <th>Shp</th>
                  <th>Clr</th>
                  <th>Prt</th>
                  <th>Cut</th>
                  <th>PL</th>
                  <th>Symm</th>
                  <th>Flue</th>
                  <th>TN</th>
                  <!-- <th>Stn</th> -->
                  <th>Ratio</th>
                  <th>tabl</th>
                  <th>Height</th>
                  <th>R Rate ($)</th>
                  <th>Back (%)</th>
                  <th>Value ($)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="border" v-for="(plan,index) in page_data.manualPlanning" :key="index">
                  <td>
                    <p>{{ index + 1 }}</p>
                  </td>
                  <td>
                    <input v-model="plan.weight" type="number"
                    @change="loadPlanningValues(plan)"
                      class="form-control w-16" placeholder="Weight" onpaste="return false;">
                  </td>
                  <td>
                    <input v-model="plan.polish_weight" type="number"
                    @change="loadPlanningValues(plan)"
                      class="form-control w-16" placeholder="Polish Weight" onpaste="return false;">
                  </td>
                  <td>
                    <select
                        class="form-select"
                        v-model="plan.shape"
                        @change="loadPlanningValues(plan)"
                        aria-label="Default select example"
                      >
                      <option :value="shape.id" v-for="shape in diamond.shapes" :key="shape.id">{{ shape.shape_name }}</option>
                    </select>
                  </td>
                  <td>
                    <select
                        class="form-select"
                        v-model="plan.color"
                        @change="loadPlanningValues(plan)"
                        aria-label="Default select example"
                      >
                      <option :value="shape.id" v-for="shape in diamond.colors" :key="shape.id">{{ shape.color_name }}</option>
                    </select>
                  </td>
                  <td>
                    <select
                        class="form-select"
                        v-model="plan.purity"
                        @change="loadPlanningValues(plan)"
                        aria-label="Default select example"
                      >
                      <option :value="shape.id" v-for="shape in diamond.purities" :key="shape.id">{{ shape.purity_name }}</option>
                    </select>
                  </td>
                  <td>
                    <select
                        class="form-select"
                        v-model="plan.cut"
                        @change="loadPlanningValues(plan)"
                        aria-label="Default select example"
                      >
                      <option :value="shape.id" v-for="shape in diamond.cuts" :key="shape.id">{{ shape.cut_name }}</option>
                    </select>
                  </td>
                  <td>
                    <select
                        class="form-select"
                        v-model="plan.polish"
                        @change="loadPlanningValues(plan)"
                        aria-label="Default select example"
                      >
                      <option :value="shape.id" v-for="shape in diamond.polish" :key="shape.id">{{ shape.polish_name }}</option>
                    </select>
                  </td>
                  <td>
                    <select
                        class="form-select"
                        v-model="plan.symmetry"
                        @change="loadPlanningValues(plan)"
                        aria-label="Default select example"
                      >
                      <option :value="shape.id" v-for="shape in diamond.symmetry" :key="shape.id">{{ shape.symmetry_name }}</option>
                    </select>
                  </td>
                  <td>
                    <select
                        class="form-select"
                        v-model="plan.fluerocent"
                        @change="loadPlanningValues(plan)"
                        aria-label="Default select example"
                      >
                      <option :value="shape.id" v-for="shape in diamond.fluerocents" :key="shape.id">{{ shape.fluerocent_name }}</option>
                    </select>
                  </td>
                  <td>
                    <select
                        class="form-select"
                        v-model="plan.tension"
                        @change="loadPlanningValues(plan)"
                        aria-label="Default select example"
                      >
                      <option :value="shape.id" v-for="shape in diamond.tensions" :key="shape.id">{{ shape.tension_name }}</option>
                    </select>
                  </td>
                  <td>
                    <select
                        class="form-select"
                        v-model="plan.ratio"
                        @change="loadPlanningValues(plan)"
                        aria-label="Default select example"
                      >
                      <option :value="ratio.id" v-for="ratio in diamond.ratios" :key="ratio.id">{{ ratio.ratio_name }}</option>
                    </select>
                  </td>
                  <td>
                    <select
                        class="form-select"
                        v-model="plan.tabl"
                        @change="loadPlanningValues(plan)"
                        aria-label="Default select example"
                      >
                      <option :value="tabl.id" v-for="tabl in diamond.tabls" :key="tabl.id">{{ tabl.tabl_name }}</option>
                    </select>
                  </td>
                  <td>
                    <select
                        class="form-select"
                        v-model="plan.height"
                        @change="loadPlanningValues(plan)"
                        aria-label="Default select example"
                      >
                      <option :value="height.id" v-for="height in diamond.heights" :key="height.id">{{ height.height_name }}</option>
                    </select>
                  </td>
                  <td>
                    <input v-model="plan.r_rate" type="text" disabled
                      class="form-control w-20" placeholder="R rate" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
                  </td>
                  <td>
                    <input v-model="plan.discount" type="text" disabled
                    class="form-control w-20" placeholder="Back" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
                  </td>
                  <td>
                    <input v-model="plan.value" type="text" disabled @change="getPlanningTotal"
                      class="form-control w-20" placeholder="Value" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
                  </td>
                  <td>
                    <button type="button" class="text-theme-6 flex items-center" tabindex="-1"  @click="removeField(plan.id)">
                      <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1"/>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr class="border">
                  <td>
                  </td>
                  <td :class="{'text-theme-6' : $h.myFloat(page_data.menual_total.weight ? page_data.menual_total.weight : 0) > $h.myFloat(page_data.pack_weight)}">
                    <p>{{ page_data.menual_total.weight }}</p>
                  </td>
                  <td :class="{'text-theme-6' : $h.myFloat(page_data.menual_total.polish_weight ? page_data.menual_total.polish_weight : 0) > $h.myFloat(page_data.pack_weight)}">
                    <p>{{ page_data.menual_total.polish_weight }}</p>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td> <p>{{ $h.myFloat(page_data.menual_total.value ? page_data.menual_total.value : 0) }}</p></td>
                </tr>
              </tbody>


            </table>

          <div>
              <button type="button" @click="AddFieldPlan" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">
                New Part Planning
              </button>
          </div>

          </div>

          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" @click="submitManualPlanning" :disabled="page_data.loading" class="btn btn-primary">Save Details
              <LoadingIcon v-if="page_data.loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
     <!-- BEGIN: Plans Details -->
     <div id="plan-details-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <div class="flex items-center justify-between w-full">
              <h2 class="font-medium text-base mr-auto">Packet Planning Details</h2>
              <div>
                <button class="btn btn-primary mr-3 btn-sm" v-if="([1,2,8].includes(user_details.user_role) && page_data.packet_details.part_ready == null && page_data.packet_details.status == 2 && page_data.packet_details.process_id != null && [3,4,5].includes(page_data.packet_details.process_type)) || ([4,6,5].includes(user_details.user_role) && page_data.packet_details.status == 2 && page_data.packet_details.employee_id == user_details.account_id && page_data.packet_details.employee_role == user_details.user_role && page_data.packet_details.part_ready == null)"
                @click="addNewPlanning(page_data.pack_id)">Add Planning</button>
                <!-- <button class="btn btn-primary mr-3 btn-sm" v-if="([1,2,8].includes(user_details.user_role) && page_data.packet_details.pack_type == 1 && page_data.packet_details.part_ready == null)"
                @click="addNewPlanning(page_data.pack_id)">Add Planning</button> -->
                <button class="btn btn-primary mr-3 btn-sm" v-if="page_data.plan_details.length > 0"
                v-print="'#planning-details'">Print</button>
                  <button class="btn btn-primary btn-sm mr-3" @click="SetImportDetails" v-if="([1,2,8].includes(user_details.user_role) && page_data.packet_details.part_ready == null && page_data.packet_details.status == 2 && page_data.packet_details.process_id != null && [3,4,5].includes(page_data.packet_details.process_type)) || ([4,6,5].includes(user_details.user_role) && page_data.packet_details.status == 2 && page_data.packet_details.employee_id == user_details.account_id && page_data.packet_details.employee_role == user_details.user_role && page_data.packet_details.part_ready == null)">Import
                  </button>
              </div>
            </div>
          </div>
          <div class="modal-body p-3" id="planning-details">

            <div class="flex flex-wrap">

              <p class="font-medium text-base mr-auto"><b>Packet Number : </b>{{ page_data.pack_code }} {{ page_data.packet_details.vepari_no ? ' ( '+page_data.packet_details.vepari_no+' ) ' : '' }}  <span v-if="page_data.cosing_value">( {{ page_data.cosing_value }} $ )</span></p>
              <p class="font-medium text-base mr-auto" v-if="page_data.packet_details.final_plan_date"><b>Final Plan Date : </b>{{ page_data.packet_details.final_plan_date ? page_data.packet_details.final_plan_date : '' }}</p>
            </div>
            <div class="mt-3">
              <table class="mt-3 w-full border-2">
                <thead>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">No</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Makeable Wt (Ct)</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt (Ct)</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Pieces</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Planner / Checker /  Job Party</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Total Plan Value ($)</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Plan Date</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Status</th>
                    <th class="py-2 border-b text-xs lg:text-md text-center border-r">Action</th>
                  </tr>
                </thead>
                <tbody v-for="(plan, index) in page_data.plan_details" :key="index" class="border hover:bg-gray-200 p-0 cursor-pointer">
                  <tr class="border-b bg-gray-100 dark:bg-dark-1">
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{ index + 1 }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{ plan.total_weight }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{ plan.total_pwt }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{ plan.pieces }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{ [1,2,8].includes(plan.planner_role) ? (plan.manager_first_name + ' ' + plan.manager_last_name) : ([5,6].includes(plan.planner_role) ? (plan.first_name + ' ' + plan.last_name) : ([4].includes(plan.planner_role) ? (plan.party_first_name + ' ' + plan.party_last_name) : "-")) }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer">{{ plan.price }}
                      <span v-if="page_data.cosing_value">
                        <span v-if="plan.price - page_data.cosing_value > 0" class="text-theme-9">
                          <ArrowUpIcon class="h-6 w-6 p-1"/> ( {{ parseFloat(100 * (plan.price - page_data.cosing_value) /page_data.cosing_value).toFixed(2) }} % )
                        </span>
                        <span v-else class="text-theme-6">
                          <ArrowDownIcon class="h-6 w-6 p-1"/> ( {{ parseFloat(100 * (page_data.cosing_value - plan.price) /page_data.cosing_value).toFixed(2) }} % )
                        </span>
                      </span>
                      <span v-if="page_data.cosing_value_manager && [1,2,8,6,3].includes(user_details.user_role)">
                        <span v-if="plan.price - page_data.cosing_value_manager > 0" class="text-theme-9">
                          <ArrowUpIcon class="h-6 w-6 p-1"/> ( {{ parseFloat(100 * (plan.price - page_data.cosing_value_manager) /page_data.cosing_value_manager).toFixed(2) }} % )
                        </span>
                        <span v-else class="text-theme-6">
                          <ArrowDownIcon class="h-6 w-6 p-1"/> ( {{ parseFloat(100 * (page_data.cosing_value_manager - plan.price) /page_data.cosing_value_manager).toFixed(2) }} % )
                        </span>
                      </span>
                    </th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer w-20">{{ plan.created_at }}</th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r cursor-pointer"><span class="text-theme-6" v-if="plan.status == 0">{{ plan.status == 0 ? 'Active' : 'Selected' }}</span><span class="text-theme-9" v-else>{{ plan.status == 0 ? 'Active' : 'Selected' }}</span></th>
                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">
                      <div v-if="[1,2,8,3].includes(user_details.user_role)">
                        <div class="flex justify-center items-center">
                          <a class="btn btn-primary flex items-center mr-3 p-1" href="javascript:;" v-if="plan.planner_id == user_details.account_id && plan.planner_role == user_details.user_role && plan.planner_role == user_details.user_role && page_data.packet_details.part_ready == null" @click="updatePlanDetails(plan.id,plan.packet_id,plan.cr_weight,plan.stone)">
                            <CheckSquareIcon class="w-4 h-4 mr-1" />
                            Edit
                          </a>
                          <a class="btn btn-primary flex items-center mr-3 p-1" href="javascript:;" v-if="[1,2,8,3].includes(user_details.user_role) && plan.status == 0 && page_data.packet_details.part_ready == null && page_data.packet_details.status == 2" @click="finalPlanDone(plan.id,plan.packet_id)">
                            <CheckSquareIcon class="w-4 h-4 mr-1" />
                            Final
                          </a>
                          <a class="btn btn-danger flex items-center mr-3 p-1" href="javascript:;" v-if="[1,2,8,3].includes(user_details.user_role) && plan.status == 1 && page_data.packet_details.part_ready == null && page_data.packet_details.status == 2" @click="removefinalPlanDone(plan.id,plan.packet_id)">
                            <CheckSquareIcon class="w-4 h-4 mr-1" />
                            Remove Final
                          </a>
                          <a class="btn btn-danger flex items-center mr-2 p-1" href="javascript:;" v-if="plan.planner_id == user_details.account_id && plan.planner_role == user_details.user_role && page_data.packet_details.part_ready == null" @click="deletePlanDetails(plan.id,plan.packet_id)">
                          <TrashIcon class="w-4 h-4 mr-1" />

                        </a>
                        <a class="btn btn-primary flex items-center mr-2 p-1" href="javascript:;" v-print="'#single_plan_' + index">
                            <PrinterIcon class="w-4 h-4 mr-1" />

                          </a>
                          <!-- <a class="btn btn-primary flex items-center mr-3 p-1" href="javascript:;" v-if="[1,2,8,3].includes(user_details.user_role) && plan.status == 0 && page_data.packet_details.part_ready == null && page_data.packet_details.pack_type == 1 && page_data.packet_details.status == 2" @click="finalPlanDone(plan.id,plan.packet_id)">
                            <CheckSquareIcon class="w-4 h-4 mr-1" />
                            Final Plan
                          </a>
                          <a class="btn btn-danger flex items-center mr-3 p-1" href="javascript:;" v-if="[1,2,8,3].includes(user_details.user_role) && plan.status == 1 && page_data.packet_details.part_ready == null && page_data.packet_details.pack_type == 1 && page_data.packet_details.status == 2" @click="removefinalPlanDone(plan.id,plan.packet_id)">
                            <CheckSquareIcon class="w-4 h-4 mr-1" />
                            Remove
                          </a> -->
                        </div>
                      </div>
                      <div v-if="[6].includes(user_details.user_role)">

                      <div class="flex justify-center items-center">
                        <a class="btn btn-primary flex items-center mr-2 p-1" href="javascript:;" v-if="plan.planner_id == user_details.account_id && plan.planner_role == user_details.user_role && page_data.packet_details.part_ready == null" @click="updatePlanDetails(plan.id,plan.packet_id,plan.cr_weight,plan.stone)">
                          <CheckSquareIcon class="w-4 h-4 mr-1" />
                          Edit
                        </a>
                        <a class="btn btn-primary flex items-center mr-2 p-1" href="javascript:;" v-if="plan.status == 0 && page_data.packet_details.part_ready == null" @click="finalPlanDone(plan.id,plan.packet_id)">
                          <CheckSquareIcon class="w-4 h-4 mr-1" />
                          Final
                        </a>
                        <a class="btn btn-danger flex items-center mr-2 p-1" href="javascript:;" v-if="plan.status == 1 && page_data.packet_details.part_ready == null" @click="removefinalPlanDone(plan.id,plan.packet_id)">
                          <CheckSquareIcon class="w-4 h-4 mr-1" />
                          Remove Final
                        </a>
                        <a class="btn btn-danger flex items-center mr-2 p-1" href="javascript:;" v-if="plan.planner_id == user_details.account_id && plan.planner_role == user_details.user_role && page_data.packet_details.part_ready == null" @click="deletePlanDetails(plan.id,plan.packet_id)">
                          <TrashIcon class="w-4 h-4 mr-1" />

                        </a>
                        <a class="btn btn-primary flex items-center mr-2 p-1" href="javascript:;" v-print="'#single_plan_' + index">
                            <PrinterIcon class="w-4 h-4 mr-1" />

                          </a>
                      </div>
                      </div>
                      <div v-if="[4,5].includes(user_details.user_role)">

                      <div class="flex justify-center items-center">
                        <a class="btn btn-primary flex items-center mr-2 p-1" href="javascript:;" v-if="plan.planner_id == user_details.account_id && plan.planner_role == user_details.user_role && page_data.packet_details.part_ready == null" @click="updatePlanDetails(plan.id,plan.packet_id,plan.cr_weight,plan.stone)">
                          <CheckSquareIcon class="w-4 h-4 mr-1" />
                          Edit
                        </a>
                        <a class="btn btn-danger flex items-center mr-2 p-1" href="javascript:;" v-if="plan.planner_id == user_details.account_id && plan.planner_role == user_details.user_role && page_data.packet_details.part_ready == null" @click="deletePlanDetails(plan.id,plan.packet_id)">
                          <TrashIcon class="w-4 h-4 mr-1" />

                        </a>
                        <a class="btn btn-primary flex items-center mr-2 p-1" href="javascript:;" v-print="'#single_plan_' + index">
                            <PrinterIcon class="w-4 h-4 mr-1" />

                          </a>
                      </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <table class="my-3 w-full border-2" :id="'single_plan_' + index">
                        <thead>
                          <tr class="border-b bg-gray-200 dark:bg-dark-1">
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">No</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Makeable Wt (Ct)</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt (Ct)</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Shp</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Clr</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Prt</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Cut</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">PL</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Symm</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Fluc</th>
                            <!-- <th class="py-2 border-b text-xs lg:text-md text-center border-r">TN</th> -->
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Stn</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Ratio</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Tabl</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Height</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Repo</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Value</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Back</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Status</th>
                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="border-b" v-for="(plan_det, index) in plan.plan_details" :key="index">
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ index + 1 }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.weight }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.polish_weight }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.shape }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.color }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.purity }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.cut }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.polish }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.symmetry }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.fluerocent }}</td>
                            <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.tension }}</td> -->
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.stone }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.ratio }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.tabl }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.height }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.r_rate }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.value }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ plan_det.discount }}</td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">
                              <label for="update-profile-form-6" class="form-label">
                                <span v-if="plan_det.status == 0" class="text-theme-11 font-bold">Pending</span>
                                <span v-if="plan_det.status == 1" class="text-theme-9 font-bold">Loat Created</span>
                              </label>
                            </td>
                            <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="index == 0" :rowspan="plan.plan_details.length">{{ $h.myFloat(plan.total_value) }}</td>
                          </tr>

                        </tbody>
                      </table>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- END: Plan Details -->
  <!-- BEGIN: Plans Details -->
  <div id="upload-planning-files-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Packet Planning Details</h2>
                        <div>
                            <!-- <button class="btn btn-primary mr-3" v-if="([1,2,8].includes(user_details.user_role) && page_data.packet_details.status == 1 && page_data.packet_details.part_ready == null && page_data.packet_details.status == 1) || ([4,6,5].includes(user_details.user_role) && page_data.packet_details.status == 2 && page_data.packet_details.part_ready == null)" @click="addNewPlanning(page_data.pack_id)">Add Planning</button>
                            <button class="btn btn-primary mr-3" v-if="([1,2,8].includes(user_details.user_role) && page_data.packet_details.pack_type == 1 && page_data.packet_details.part_ready == null)" @click="addNewPlanning(page_data.pack_id)">Add Planning</button>
                            <button class="btn btn-primary" v-if="page_data.plan_details.length > 0" v-print="'#planning-details'">Print</button> -->
                        </div>
                    </div>
                </div>
                <div class="modal-body p-3">

                  <p class="font-medium text-base mr-auto"><b>Packet Number : </b>{{ page_data.plan_upload_details.number }} {{ page_data.plan_upload_details.vepari_no ? ' ( '+page_data.plan_upload_details.vepari_no+' ) ' : '' }} </p>

                  <Dropzone ref-key="dropzoneSingleRef" :options="{
                  url: page_data.base_url + 'api/plan/upload_planning_file',
                  thumbnailWidth: 150,
                  maxFilesize: 0.5,
                  headers: { Authorization: 'Bearer ' + page_data.token }
                }" class="dropzone mb-4">
                                    <div class="text-lg font-medium">
                                        Drop files here or click to upload.
                                    </div>
                                    <div class="text-gray-600">
                                        Upload Excel file with latest Planning Files. (.xlsx, .xls)
                                    </div>
                                </Dropzone>

                                <div class="flex w-full justify-end items-center">
                                  <button class="btn btn-primary btn-sm" @click="uploadPlanningFiles" :disabled="page_data.plan_upload_details.loading == true || page_data.uploaded_fn.length == 0">
                                    Upload Files <LoadingIcon v-if="page_data.plan_upload_details.loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" />
                                  </button>
                                </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: Plan Details -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { computed, defineComponent, inject, onMounted, provide, reactive, ref, toRef, watch } from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import store from '@/store'
import router from '@/router'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const dropzoneSingleRef = ref()
    const page_data = reactive({
      plan_upload_details: {
        id: '0',
        number: '',
        vepari_no: '',
        loading: false
      },
      upload_packet_id: '0',
      search: '',
      check: false,
      waiting: false,
      loading: false,
      packet_details: [],
      cosing_value: 0,
      cosing_value_manager: 0,
      message: '',
      error: '',
      total_items: 1,
      pack_id: '',
      pack_code: '',
      pack_weight: '',
      plan_id: '',
      account_id: user_details.value.account_id,
      stone: '',
      manualPlanning : [{
        id:1,
        part_no :'',
        weight: '',
        polish_weight: '',
        color: '',
        cut: '',
        purity: '',
        polish: '',
        shape: '',
        symmetry: '',
        fluerocent: '',
        tension: '',
        stone: '',
        ratio:'',
        tabl:'',
        r_rate:'',
        value:'',
        height:'',
        discount:'',
      }],
      base_url: BASE_URL,
      token: localStorage.getItem('token'),
      menual_total: {
        weight : 0,
        polish_weight : 0,
        value : 0,
      },
      plan_details: [],
      uploaded_fn: [],

      total: [],
      kapan_details: [],
      party_details: [],
    })

    const filter_data = reactive({
        kapan_id: '0',
        party_id: '0',
        stock_no: '',
        packet_type: '0',
        employee_type: '0',
        employee_id: '0',
        packet_status: '0',
    })
    const diamond = reactive({
        colors: [],
        cuts: [],
        purities: [],
        symmetry: [],
        polish: [],
        fluerocents: [],
        tensions: [],
        stones: [],
        shapes: [],
        heights: [],
        ratios: [],
        tabls: [],
      })
    const focusMe = ref()
    const swal = inject('$swal')
    const tableRef = ref()
    const tabulator = ref()

    provide('bind[dropzoneSingleRef]', el => {
      dropzoneSingleRef.value = el
    })

    function  AddFieldPlan() {
      page_data.total_items++
      page_data.manualPlanning.push(
        {
        id:page_data.total_items,
        part_no :'',
        weight: '',
        polish_weight: '',
        color: '',
        cut: '',
        purity: '',
        polish: '',
        shape: '',
        symmetry: '',
        fluerocent: '',
        tension: '',
        stone: page_data.stone,
        ratio:'',
        tabl:'',
        r_rate:'',
        value:'',
        height:'',
        discount:'',
      }
      );
      console.log(page_data.total_items);

    }
    function getDiamontData() {
        axios({
          url: 'api/master/get_all_parameters',
          method: 'post',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          diamond.colors = res.data.color
          diamond.cuts = res.data.cut
          diamond.purities = res.data.purity
          diamond.polish = res.data.polish
          diamond.shapes = res.data.shape
          diamond.symmetry = res.data.symmetry
          diamond.fluerocents = res.data.fluerocent
          diamond.tensions = res.data.tension
          diamond.stones = res.data.stone
          diamond.heights = res.data.height
          diamond.tabls = res.data.tabl
          diamond.ratios = res.data.ratio
          // page_data.labs = res.data.lab
        })
      }
    function removeField(id) {
        swal({
        title: "Are you sure?",
        text: "You are about to delete a item!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){
            var i = page_data.manualPlanning.length;
              while(i--){
              if(page_data.manualPlanning[i]['id'] == id ){
                  page_data.manualPlanning.splice(i,1);
              }
            }
          }
      });
    }

    function getPlanningTotal() {
      let length = page_data.manualPlanning.length

      page_data.menual_total.weight = 0
      page_data.menual_total.polish_weight = 0
      page_data.menual_total.value = 0

      for(let i = 0;i < length;i++) {
        page_data.menual_total.weight = parseFloat(parseFloat(page_data.menual_total.weight) + parseFloat(page_data.manualPlanning[i]['weight'] ? page_data.manualPlanning[i]['weight'] : 0)).toFixed(4)
        page_data.menual_total.polish_weight = parseFloat(parseFloat(page_data.menual_total.polish_weight) + parseFloat(page_data.manualPlanning[i]['polish_weight'] ? page_data.manualPlanning[i]['polish_weight'] : 0)).toFixed(4)
        page_data.menual_total.value = parseFloat(parseFloat(page_data.menual_total.value) + parseFloat(page_data.manualPlanning[i]['value'] ? page_data.manualPlanning[i]['value'] : 0))
      }
      if(page_data.menual_total.weight > page_data.pack_weight) {
        swal("Warning!", "Total Planning Weight Must Be less then Issue Weight!", "warning")
          var i = page_data.manualPlanning.length;
              while(i--){
              if(page_data.manualPlanning[i]['id'] == plan.id ){
                  page_data.manualPlanning[i]['polish_weight'] = ''
                  return
                }
              }
      }
    }
    function loadPlanningValues(plan) {
      if(plan.polish_weight != '' && plan.weight != '') {
        if(plan.polish_weight > plan.weight) {

          swal("Warning!", "Polish Must Less then Makeable Weight!", "warning")
          var i = page_data.manualPlanning.length;
              while(i--){
              if(page_data.manualPlanning[i]['id'] == plan.id ){
                  page_data.manualPlanning[i]['polish_weight'] = ''
                  return
                }
              }
        }
      }
      getPlanningTotal()
      if(plan.polish_weight != '' && plan.color != '' && plan.cut != '' && plan.purity != '' && plan.polish != '' && plan.shape != '' && plan.symmetry != '' && plan.fluerocent != '' && plan.height != '' && plan.ratio != '' && plan.tabl != '') {

        let shape_name = ''
        var i = diamond.shapes.length;
              while(i--){
              if(diamond.shapes[i]['id'] == plan.shape ){
                shape_name = diamond.shapes[i]['shape_group']
                }
              }
        let params = {
          color: plan.color,
          cut: plan.cut,
          purity: plan.purity,
          polish: plan.polish,
          symmetry: plan.symmetry,
          fluerocent: plan.fluerocent,
          tension: plan.tension,
          height: plan.height,
          ratio: plan.ratio,
          tabl: plan.tabl,
          shape: plan.shape,
          shape_group: shape_name,
          polish_weight: plan.polish_weight,
          stone: page_data.stone,
        }
        axios({
          url: 'api/plan/get_plan_values',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          let rap_value = res.data.rape_value
          let back = res.data.back

          var i = page_data.manualPlanning.length;
              while(i--){
              if(page_data.manualPlanning[i]['id'] == plan.id ){
                  page_data.manualPlanning[i]['r_rate'] = parseFloat(rap_value * plan.polish_weight).toFixed(2)
                  page_data.manualPlanning[i]['discount'] = back
                  page_data.manualPlanning[i]['value'] = parseFloat(page_data.manualPlanning[i]['r_rate'] - (page_data.manualPlanning[i]['r_rate'] * back / 100)).toFixed(2)
                }
              }
              getPlanningTotal()
        })
      }

    }
    function getPacketPlans(id) {

      let params = {
        packet_id : window.btoa(id)
      }
      cash('#plan-details-modal').modal('show')
      axios({
          url: 'api/plan/get_packet_plans_details',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          page_data.pack_id = id
          page_data.stone = res.data.packet_item.stone
          page_data.pack_weight = res.data.packet_item.cr_weight
          page_data.packet_details = res.data.packet_item
          page_data.plan_details = res.data.items
          page_data.pack_code = page_data.packet_details.number
          if(page_data.packet_details.owner_value) {
            page_data.cosing_value = parseFloat(parseFloat(page_data.packet_details.owner_value) + parseFloat(page_data.packet_details.majuri_type == 1 ? (page_data.packet_details.owner_value * page_data.packet_details.majuri_rate / 100) : page_data.packet_details.weight * page_data.packet_details.majuri_rate) + parseFloat(page_data.packet_details.margin_percent * page_data.packet_details.owner_value / 100)).toFixed(2)
            page_data.cosing_value_manager = parseFloat(parseFloat(page_data.packet_details.owner_value) + parseFloat(page_data.packet_details.majuri_type == 1 ? (page_data.packet_details.owner_value * page_data.packet_details.majuri_rate / 100) : page_data.packet_details.weight * page_data.packet_details.majuri_rate)).toFixed(2)
          }
          // console.warn(parseFloat(parseFloat(page_data.packet_details.owner_value) + parseFloat(page_data.packet_details.majuri_type == 1 ? (page_data.packet_details.owner_value * page_data.packet_details.majuri_rate / 100) : page_data.packet_details.majuri_rate) + parseFloat(page_data.packet_details.margin_percent * page_data.packet_details.owner_value / 100)).toFixed(2))
          setTimeout(() => {
            // cash('#plan-details-modal').modal('show')
          }, 20);
        })
    }
    function updatePlanDetails(id,pack_id,weight,stone) {

      page_data.pack_id = pack_id
      page_data.plan_id = id
      page_data.pack_weight = weight
      page_data.stone = stone

      let params = {
        packet_id : window.btoa(pack_id),
        plan_id : window.btoa(id)
      }
      axios({
          url: 'api/plan/single_plan_packet_detail',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          page_data.manualPlanning = res.data.items
          page_data.total_items = page_data.manualPlanning.length
          console.warn(page_data.total_items)
          getPlanningTotal()
          cash('#new-manual-planning').modal('show')
        })

    }
    const initTabulator = () => {

      let filter_params = {
          kapan_id: filter_data.kapan_id == '0' ? '' : window.btoa(filter_data.kapan_id),
          stock_no: filter_data.stock_no == '' ? '' : window.btoa(filter_data.stock_no),
          packet_type: filter_data.packet_type == '0' ? '' : window.btoa(filter_data.packet_type),
          employee_id: filter_data.employee_id == '0' ? '' : window.btoa(filter_data.employee_id),
          employee_type: filter_data.employee_type == '0' ? '' : window.btoa(filter_data.employee_type),
          packet_status: filter_data.packet_status == '0' ? '' : window.btoa(filter_data.packet_status),
          kapan_id: filter_data.kapan_id == '0' ? '' : window.btoa(filter_data.kapan_id),
      }
      if([1,2,3,8].includes(user_details.value.user_role)) {
        tabulator.value = new Tabulator(tableRef.value, {
          ajaxURL: BASE_URL + 'api/purchase/get_planning_available_packets_admin',
          ajaxConfig:{
              method:"GET", //set request type to Position
              headers: {
                  "Content-type": 'application/json; charset=utf-8', //set specific content type
                  "Authorization": "Bearer " + localStorage.getItem("token")
              },
          },
          ajaxParams: filter_params,
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          headerSort: false,
          pagination: 'remote',
          paginationSize: 10,
          paginationSizeSelector: [10, 20, 30, 40],
          layout: 'fitColumns',
          responsiveLayout: 'collapse',
          placeholder: 'No matching records found',
          columns: [
            {
              formatter: 'responsiveCollapse',
              width: 40,
              minWidth: 30,
              hozAlign: 'center',
              resizable: false,
              headerSort: false
            },

            // For HTML table
            {
              title: 'Packet No',
              responsive: 0,

              field: 'number',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                const a = cash(`<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().urgent == 1 ? '<span class="text-theme-6">'+ cell.getData().number +'</span>' : cell.getData().number
                  }</div>
                </div>`)

                cash(a).on('click', function() {
                        page_data.pack_code = cell.getData().number
                        getPacketPlans(cell.getData().id)

                      })
                      return a[0]
              }
            },
            {
              title: 'Stock No',
              responsive: 0,
              field: 'vepari_no',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().vepari_no
                  }</div>
                </div>`
              }
            },
            {
              title: 'Type',
              responsive: 0,
              field: 'type',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().type
                  }</div>
                </div>`
              }
            },
            {
              title: 'Weight',
              responsive: 0,
              field: 'weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().weight
                  }</div>
                </div>`
              }
            },
            {
              title: ' Current Weight',
              responsive: 0,
              field: 'cr_weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().cr_weight
                  }</div>
                </div>`
              }
            },
            {
              title: 'Stn',
              field: 'stone_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false
            },
            {
              title: 'Est Planning',
              field: 'est_planning',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().owner_value ? parseFloat(parseFloat(cell.getData().owner_value) + (parseFloat(cell.getData().majuri_type == 1 ? (cell.getData().owner_value * cell.getData().majuri_rate / 100) : cell.getData().weight * cell.getData().majuri_rate)) + parseFloat(cell.getData().margin_percent * cell.getData().owner_value / 100)).toFixed(2) + ' $' : '-'
                  }</div>
                </div>`
              }
            },
            {
              title: 'Total Plan',
              field: 'est_planning',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().total_plan ? cell.getData().total_plan : '-'
                  }</div>
                </div>`
              }
            },
            {
              title: 'Plans',
              field: '',
              hozAlign: 'center',
              vertAlign: 'middle',
              headerSort:false,
              print: false,
              download: false,
              columns: [
                {
                  title: '',
                  field: '',
                  hozAlign: 'center',
                  vertAlign: 'middle',
                  headerSort:false,
                  print: false,
                  download: false,
                  width: 100,
                  formatter(cell) {
                    if(cell.getData().part_ready == null && ((cell.getData().status == 2 && cell.getData().process_id != null && [3,4,5].includes(cell.getData().process_type)))) {
                      const a = cash(`<div class="flex lg:justify-center items-center">
                        <button class="btn btn-primary btn-sm flex items-center mr-3"> New Plan
                          </button>
                          </div>`)
                          cash(a).on('click', function() {
                            page_data.stone = cell.getData().stone_id
                            page_data.pack_weight = cell.getData().cr_weight
                            addNewPlanning(cell.getData().id)
                      })
                      return a[0]
                    }
                  }
                },
                {
                  title: '',
                  field: '',
                  hozAlign: 'center',
                  vertAlign: 'middle',
                  headerSort:false,
                  print: false,
                  download: false,
                  width: 100,
                  formatter(cell) {
                    if(cell.getData().part_ready == null && ((cell.getData().status == 2 && cell.getData().process_id != null && [3,4,5].includes(cell.getData().process_type)))) {

                      const a = cash(`<div class="flex lg:justify-center items-center">
                        <button class="btn btn-primary btn-sm flex items-center mr-3">Import
                        </button>
                      </div>`)
                      cash(a).on('click', function() {
                        page_data.plan_upload_details.number = cell.getData().number
                        page_data.plan_upload_details.vepari_no = cell.getData().vepari_no
                        page_data.plan_upload_details.id = cell.getData().id
                        page_data.upload_packet_id = cell.getData().id

                        console.log(page_data.plan_upload_details)
                        setTimeout(() => {
                          console.log(page_data.upload_packet_id)
                        }, 200);
                        cash('#upload-planning-files-modal').modal('show')

                      })
                      return a[0]
                    }
                  }
                },
                {
                  title: '',
                  field: '',
                  hozAlign: 'center',
                  vertAlign: 'middle',
                  headerSort:false,
                  print: false,
                  download: false,
                  width: 100,
                  formatter(cell) {
                    // if(cell.getData().part_ready == null && ((cell.getData().status == 2) || (cell.getData().pack_type == 1))) {
                      const a = cash(`<div class="flex lg:justify-center items-center">
                        <button class="btn btn-success btn-sm flex items-center mr-3">
                          <i data-feather="check-square" class="w-4 h-4 mr-1"></i> View
                        </button>
                      </div>`)
                      cash(a).on('click', function() {
                        page_data.pack_code = cell.getData().number
                        getPacketPlans(cell.getData().id)

                      })
                      return a[0]
                    // }
                  }
                }
              ],
            },
          ],
          renderComplete() {
            feather.replace({
              'stroke-width': 1.5
            })
          }
        })

        let promise = axios({
                url: "api/purchase/get_planning_available_packets_admin_total",
                method: "post",
                data: filter_params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    console.log(data);
                    page_data.total = data.total
                })
                .catch(_error => {
                    page_data.total = []
                });
      } else if(user_details.value.user_role == 4 || user_details.value.user_role == 5 || user_details.value.user_role == 6) {
        tabulator.value = new Tabulator(tableRef.value, {
          ajaxURL: BASE_URL + 'api/purchase/get_available_packets',
          ajaxConfig:{
              method:"GET", //set request type to Position
              headers: {
                  "Content-type": 'application/json; charset=utf-8', //set specific content type
                  "Authorization": "Bearer " + localStorage.getItem("token")
              },
          },
          ajaxParams: filter_params,
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          headerSort: false,
          pagination: 'remote',
          paginationSize: 10,
          paginationSizeSelector: [10, 20, 30, 40],
          layout: 'fitColumns',
          responsiveLayout: 'collapse',
          placeholder: 'No matching records found',
          columns: [
            {
              formatter: 'responsiveCollapse',
              width: 40,
              minWidth: 30,
              hozAlign: 'center',
              resizable: false,
              headerSort: false
            },

            // For HTML table
            {
              title: 'Packet No',
              responsive: 0,

              field: 'number',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                const a =  cash(`<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().urgent == 1 ? '<span class="text-theme-6">'+ cell.getData().number +'</span>' : cell.getData().number
                  }</div>
                </div>`)


                cash(a).on('click', function() {
                        page_data.pack_code = cell.getData().number
                        getPacketPlans(cell.getData().id)

                      })
                      return a[0]
              }
            },
            {
              title: 'Stock No',
              responsive: 0,
              field: 'vepari_no',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().vepari_no
                  }</div>
                </div>`
              }
            },
            {
              title: 'Type',
              responsive: 0,
              field: 'type',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().type
                  }</div>
                </div>`
              }
            },
            {
              title: 'Weight',
              responsive: 0,
              field: 'weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().weight
                  }</div>
                </div>`
              }
            },
            {
              title: ' Current Weight',
              responsive: 0,
              field: 'cr_weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().cr_weight
                  }</div>
                </div>`
              }
            },
            {
              title: 'Stn',
              field: 'stone_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false
            },
            {
              title: 'Est Planning',
              field: 'est_planning',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().owner_value ? parseFloat(parseFloat(cell.getData().owner_value) + parseFloat(cell.getData().majuri_type == 1 ? (cell.getData().owner_value * cell.getData().majuri_rate / 100) : cell.getData().weight * cell.getData().majuri_rate) + parseFloat(cell.getData().margin_percent * cell.getData().owner_value / 100)).toFixed(2) + ' $' : '-'
                  }</div>
                </div>`
              }
            },
            {
              title: 'Total Plan',
              field: 'est_planning',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().total_plan ? cell.getData().total_plan : '-'
                  }</div>
                </div>`
              }
            },
            {
              title: 'Plans',
              field: '',
              hozAlign: 'center',
              vertAlign: 'middle',
              headerSort:false,
              print: false,
              download: false,
              columns: [
                {
                  title: '',
                  field: '',
                  hozAlign: 'center',
                  vertAlign: 'middle',
                  headerSort:false,
                  print: false,
                  download: false,
                  width: 100,
                  formatter(cell) {
                    if(cell.getData().part_ready == null && cell.getData().process_id != null && cell.getData().employee_id == user_details.value.account_id && cell.getData().employee_role == user_details.value.user_role && cell.getData().status == 2) {

                      const a = cash(`<div class="flex lg:justify-center items-center">
                        <button class="btn btn-primary btn-sm flex items-center mr-3"> New Plan
                          </button>
                          </div>`)
                          cash(a).on('click', function() {
                            page_data.stone = cell.getData().stone_id
                            page_data.pack_weight = cell.getData().cr_weight
                            addNewPlanning(cell.getData().id)
                      })
                      return a[0]
                    }
                  }
                },
                {
                  title: '',
                  field: '',
                  hozAlign: 'center',
                  vertAlign: 'middle',
                  headerSort:false,
                  print: false,
                  download: false,
                  width: 100,
                  formatter(cell) {
                    if(cell.getData().part_ready == null && cell.getData().process_id != null && cell.getData().employee_id == user_details.value.account_id && cell.getData().employee_role == user_details.value.user_role && cell.getData().status == 2) {

                      const a = cash(`<div class="flex lg:justify-center items-center">
                        <button class="btn btn-primary btn-sm flex items-center mr-3">Import
                        </button>
                      </div>`)
                      cash(a).on('click', function() {
                        page_data.plan_upload_details.number = cell.getData().number
                        page_data.plan_upload_details.vepari_no = cell.getData().vepari_no
                        page_data.plan_upload_details.id = cell.getData().id
                        page_data.upload_packet_id = cell.getData().id

                        console.log(page_data.plan_upload_details)
                        setTimeout(() => {
                          console.log(page_data.upload_packet_id)
                        }, 200);
                        cash('#upload-planning-files-modal').modal('show')

                      })
                      return a[0]
                    }
                  }
                },
                {
                  title: '',
                  field: '',
                  hozAlign: 'center',
                  vertAlign: 'middle',
                  headerSort:false,
                  print: false,
                  download: false,
                  width: 100,
                  formatter(cell) {
                      const a = cash(`<div class="flex lg:justify-center items-center">
                        <button class="btn btn-success btn-sm flex items-center mr-3">
                          <i data-feather="check-square" class="w-4 h-4 mr-1"></i> View
                        </button>
                      </div>`)
                      cash(a).on('click', function() {
                        page_data.pack_code = cell.getData().number
                        getPacketPlans(cell.getData().id)

                      })
                      return a[0]
                  }
                }
              ],
            },
          ],
          renderComplete() {
            feather.replace({
              'stroke-width': 1.5
            })
          }
        })

        let promise = axios({
                url: "api/purchase/available_packets_total",
                method: "post",
                data: filter_params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    console.log(data);
                    page_data.total = data.total
                })
                .catch(_error => {
                    page_data.total = []
                });
      }

    }
    function addNewPlanning(id) {
      // if(page_data.pack_id != id) {

        page_data.manualPlanning = [{
          id:1,
          part_no :'',
          weight: '',
          polish_weight: '',
          color: '',
          cut: '',
          purity: '',
          polish: '',
          shape: '',
          symmetry: '',
          fluerocent: '',
          tension: '',
          stone: page_data.stone,
          ratio:'',
          tabl:'',
          r_rate:'',
          value:'',
          height:'',
          discount:'',
        }]

        getPlanningTotal()

        page_data.pack_id = id
        page_data.total_items = 1
      // } else {

      // }
      // cash('#plan-details-modal').modal('hide')
      cash('#new-manual-planning').modal('show')
    }

    function submitManualPlanning() {

      let submit = true
      page_data.manualPlanning.forEach(plan => {
        if(plan.weight == '' || plan.polish_weight == '' || plan.color == '' || plan.cut == '' || plan.purity == '' || plan.polish == '' || plan.shape == '' || plan.symmetry == '' || plan.fluerocent == '' || plan.height == '' || plan.ratio == '' || plan.tabl == '') {
          swal("Warning!", "Fileds are Empty !", "warning")
          submit = false
        }
      });

      if(submit == true) {

        page_data.loading = true
        let params = {
          plan_details :page_data.manualPlanning,
          packet_id :page_data.pack_id,
          plan_id : page_data.plan_id,
          account_id : page_data.account_id,
          menual_total : page_data.menual_total,
        }

        axios({
            url: 'api/plan/add_plan_values',
            method: 'post',
            baseURL: BASE_URL,
            data: params,
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {

            cash('#new-manual-planning').modal('hide')
            page_data.manualPlanning = [{
            id:1,
            part_no :'',
            weight: '',
            polish_weight: '',
            color: '',
            cut: '',
            purity: '',
            polish: '',
            shape: '',
            symmetry: '',
            fluerocent: '',
            tension: '',
            stone: '',
            ratio:'',
            tabl:'',
            r_rate:'',
            value:'',
            height:'',
            discount:'',
          }]
          getPacketPlans(page_data.pack_id)
          page_data.total_items = 1
          page_data.pack_id = ''
          page_data.pack_weight = ''
          page_data.plan_id = ''
          page_data.message = res.data.success

          page_data.menual_total.polish_weight = 0
          page_data.menual_total.weight = 0
          page_data.menual_total.value = 0
          initTabulator()
          setTimeout(() => {
            basicNonStickyNotificationPacketSuccess()
          },100)
          setTimeout(() => {
            page_data.message = ''
          }, 3000);
          page_data.loading = false
          }).catch((err) => {
            page_data.error = err.response.data.error
            page_data.loading = false
            setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          setTimeout(() => {
            page_data.error = ''
          }, 3000);
          })
      }

    }

    function finalPlanDone(plan_id,packet_id) {

      swal({
        title: "Are you sure?",
        text: "You are make a final Plan!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if(result.isConfirmed){
          let params = {
            packet_id : window.btoa(packet_id),
            plan_id : window.btoa(plan_id)
          }
          axios({
              url: 'api/plan/final_plan_packet_detail',
              method: 'post',
              baseURL: BASE_URL,
              data: params,
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then((res) => {
              page_data.message = res.data.success
              getPacketPlans(packet_id)
              setTimeout(() => {
                basicNonStickyNotificationPacketSuccess()
              },100)
              setTimeout(() => {
                page_data.message = ''
              }, 3000);
            })
        }
      });

    }
    function removefinalPlanDone(plan_id,packet_id) {

      swal({
        title: "Are you sure?",
        text: "You wan't to Cancle Final Plan!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Remove",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if(result.isConfirmed){
          let params = {
            packet_id : window.btoa(packet_id),
            plan_id : window.btoa(plan_id)
          }
          axios({
              url: 'api/plan/remove_final_plan_packet_detail',
              method: 'post',
              baseURL: BASE_URL,
              data: params,
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then((res) => {
              page_data.message = res.data.success
              getPacketPlans(packet_id)
              setTimeout(() => {
                basicNonStickyNotificationPacketSuccess()
              },100)
              setTimeout(() => {
                page_data.message = ''
              }, 3000);
            })
        }
      });

    }

    const deletePlanDetails = (id, packet_id) => {

swal({
          title: "Are you sure?",
          text: "You are about to Delete Plan!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Reject",
          showLoaderOnConfirm: true,
          cancelButtonText: "Cancel",

          allowOutsideClick: () => swal.isLoading()
      }).then(result => {
          if (result.isConfirmed) {

              const params = {
                plan_id: (id != '' ? window.btoa(id) : ''),
              }

              let promise_url = 'api/plan/delete_plan'
              let promise = axios({
                  url: promise_url,
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.status == 'error') {
                          swal('Warning', data.message, 'warning');
                      } else {
                          swal('Success', data.message, 'success');
                          getPacketPlans(packet_id)
                          initTabulator()
                      }
                  })
                  .catch(_error => {
                      swal('Error', 'Something went wrong.', 'error');
                  });

          }
      });
}
const get_party_details = () => {
      const params = {
        process_type_tr: window.btoa(5),
      }

      axios({
          url: 'api/master/get_jober_details_by_process',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
              headers: {
                  'Accept': 'application/json'
              }
          }
      }).then((res) => {
          page_data.party_details = res.data.items
      })

    }

    const getLotDetails = () => {
            axios({
                url: 'api/master/dp_lot_details',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                page_data.kapan_details = res.data.items
            })
        }
    function checkadd() {
      if(page_data.search) {
        const params = {
          pkt_no : window.btoa(page_data.search)
        }
        axios({
          url: 'api/plan/get_planner_packet_id_admin',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          let packet_id = res.data.packet
          page_data.pack_code = page_data.search
          page_data.search = ''
          getPacketPlans(packet_id)
        }).catch((err) => {
          page_data.error = err.response.data.error
          page_data.search = ''
          focusMe.value.focus()
          console.warn(page_data.error)
          setTimeout(() => {
            basicNonStickyNotificationPacket()
          },100)
          setTimeout(() => {
            page_data.error = ''
          }, 3000);

        })
      }
    }

    const uploadPlanningFiles = () => {

      if(page_data.uploaded_fn.length > 0) {

        let params = {
          file_items: page_data.uploaded_fn,
          packet_id: page_data.plan_upload_details.id
        }
        page_data.plan_upload_details.loading = true

        axios({
          url: 'api/plan/read_planning_file',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {


          let pkt_id = page_data.plan_upload_details.id
          let pkt_no = page_data.plan_upload_details.number
          page_data.plan_upload_details.loading = false
          page_data.plan_upload_details.id = ''
          page_data.upload_packet_id = ''
          page_data.plan_upload_details.number = ''
          page_data.plan_upload_details.vepari_no = ''
          page_data.uploaded_fn = []

          swal.fire({
            text: "Files Uploaded.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "OK!",
                heightAuto: false,
                customClass: {
                  confirmButton: "btn fw-semibold btn-light-danger",
                },
                timer: 2000
              });
              // myDropzone.removeFile(file);
      //         const elDropzoneSingleRef = dropzoneSingleRef.value
      // elDropzoneSingleRef.removeAllFiles(true)
              cash('#upload-planning-files-modal').modal('hide')
            page_data.pack_code = pkt_no
            getPacketPlans(pkt_id)
          // let packet_id = res.data.packet
          // page_data.pack_code = page_data.search
          // page_data.search = ''
          // getPacketPlans(packet_id)
        }).catch((err) => {
          page_data.plan_upload_details.loading = false
          swal('Warning','Something went wrong.','warning')

        })
        console.log(page_data.uploaded_fn)
      } else {
        swal('Warning', 'Upload file First','warning')
      }
    }


    const resetFilters = () => {
        filter_data.kapan_id = '0'
        filter_data.party_id = '0'
        filter_data.stock_no = ''
        filter_data.packet_type = '0'
        filter_data.employee_type = '0'
        filter_data.employee_id = '0'
        filter_data.packet_status = '0'

        initTabulator()
      }

    const SetImportDetails = () => {
      page_data.plan_upload_details.number = page_data.packet_details.number
      page_data.plan_upload_details.vepari_no = page_data.packet_details.vepari_no
      page_data.plan_upload_details.id = page_data.packet_details.id
      page_data.upload_packet_id = page_data.packet_details.id

      console.log(page_data.plan_upload_details)
      setTimeout(() => {
        console.log(page_data.upload_packet_id)
      }, 200);
      cash('#upload-planning-files-modal').modal('show')
    }
    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    const basicNonStickyNotificationPacketSuccess = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-success')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    function getPacketDetailsScan() {
        if(!page_data.check && page_data.search) {
          checkadd();
        }
      }
    onMounted(() => {
      initTabulator()
      getDiamontData()
      getLotDetails()
      get_party_details()
      focusMe.value.focus()

      const elDropzoneSingleRef = dropzoneSingleRef.value
      elDropzoneSingleRef.dropzone.on('success', (data,response) => {
        console.log(data);
        console.log(response);

        page_data.uploaded_fn.push(response.fn);
        console.log(page_data.uploaded_fn);
        //alert('Added file.')

      })
      elDropzoneSingleRef.dropzone.on('error', () => {
        alert('Something went wrong!')
      })
    })
    return {
      page_data,
      focusMe,
      user_details,
      tableRef,
      diamond,
      getPacketDetailsScan,
      checkadd,
      initTabulator,
      AddFieldPlan,
      removeField,
      loadPlanningValues,
      getPlanningTotal,
      submitManualPlanning,
      updatePlanDetails,
      finalPlanDone,
      removefinalPlanDone,
      resetFilters,
      addNewPlanning,
      filter_data,
      uploadPlanningFiles,SetImportDetails,
      deletePlanDetails
    }
  }
})
</script>
<style scoped>
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        .input-group .input-group-text-custom {
            border-width: 1px;
            --tw-border-opacity: 1;
            border-color: rgba(226, 232, 240, var(--tw-border-opacity));
            --tw-bg-opacity: 1;
            background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            --tw-text-opacity: 1;
            color: rgba(113, 128, 150, var(--tw-text-opacity));
            --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    </style>


