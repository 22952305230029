<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-4">
      <h2 class="text-lg font-medium mr-auto">LAB Stock (Received Results)</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div class="w-1/2 sm:w-auto mx-1">
          <button class="btn btn-outline-secondary bg-white w-full sm:w-auto" @click="exportCurrentStocks">
            <FileTextIcon class="w-4 h-4 mr-2" /> Export
            <ChevronUpIcon class="w-4 h-4 ml-auto sm:ml-2" />
          </button>
        </div>
        <div class="w-1/2 sm:w-auto mx-1">
          <button class="btn btn-primary w-full sm:w-auto" @click="inputFileClick"
            :disabled="page_data.excel_loading == true">
            <FileTextIcon class="w-4 h-4 mr-2" /> Update Stocks(Excel)
            <LoadingIcon v-if="page_data.excel_loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" />
            <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
          </button>


        </div>
        <div class="w-1/2 sm:w-auto mx-1">

          <button
           class="btn btn-primary w-full sm:w-auto mx-2" @click="takeMultiReturn" v-if="page_data.form_status == true">
           Save Details
           <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!page_data.return_progress"/>
          </button>
        </div>

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box px-5 py-3 mt-3">
      <div v-if="page_data.excel_loading == true">

      </div>
      <div v-else>

        <div class="" v-if="page_data.form_status == false">
          <div>
            <div class="grid grid-cols-12 gap-2">
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="border rounded zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                            <div class="text-base text-gray-600">Total Available</div>
                            <div class="text-lg font-medium leading-5">{{ page_data.heading_total.total_available_pcs ? $h.myFloat(page_data.heading_total.total_available_pcs) : 0 }} ({{ page_data.heading_total.total_available_weight ? $h.myFloat(page_data.heading_total.total_available_weight) + ' Ct' : '0 Ct' }})</div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="border rounded zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                            <div class="text-base text-gray-600">Live Stock</div>
                            <div class="text-lg font-medium leading-5">{{ page_data.heading_total.total_live_pcs ? $h.myFloat(page_data.heading_total.total_live_pcs) : 0 }} ({{ page_data.heading_total.total_live_weight ? $h.myFloat(page_data.heading_total.total_live_weight) + ' Ct' : '0 Ct' }})</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="border rounded zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                            <div class="text-base text-gray-600">Certi Live Pending</div>
                            <div class="text-lg font-medium leading-5">{{ page_data.heading_total.total_certi_pcs ? $h.myFloat(page_data.heading_total.total_certi_pcs) : 0 }} ({{ page_data.heading_total.total_certi_weight ? $h.myFloat(page_data.heading_total.total_certi_weight) + ' Ct' : '0 Ct' }})</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="border rounded zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                            <div class="text-base text-gray-600">Loose Live Pending</div>
                            <div class="text-lg font-medium leading-5">{{ page_data.heading_total.total_loose_pcs ? $h.myFloat(page_data.heading_total.total_loose_pcs) : 0 }} ({{ page_data.heading_total.total_loose_weight ? $h.myFloat(page_data.heading_total.total_loose_weight) + ' Ct' : '0 Ct' }})</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div class="grid grid-cols-12 gap-4 mt-2">
              <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                <div>
                  <input id="tabulator-html-filter-value" v-model="filter_data.stock_no" type="text"
                    class="form-control 2xl:w-full" placeholder="Stock No" />
                </div>
              </div>
              <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                <div>
                  <TomSelect v-model="filter_data.kapan_id" :options="{
                    placeholder: 'Select Kapan',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Kapan'
                      }
                    }
                  }" class="w-full" @change="initTabulator">
                    <option value="0">Select Kapan</option>
                    <option v-for="(item, index) in page_data.kapan_details" :key="index" :value="item.id">{{ item.lot_no }} ({{
                      item.voucher_no }})</option>
                  </TomSelect>
                </div>
              </div>
              <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                <div>
                  <TomSelect v-model="filter_data.packet_type" :options="{
                    placeholder: 'Select Packet Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Packet Type'
                      }
                    }
                  }" class="w-full" @change="initTabulator">
                    <option :value="lab.id" v-for="(lab, index) in page_data.labs" :key="index">{{ lab.lab_code }}</option>
                    <option value="mix">Mix Packet</option>
                    <option value="non-cert">Non Certy</option>
                  </TomSelect>
                </div>
              </div>
              <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                <div>
                  <button class="btn btn-secondary mr-2" @click="resetFilters">
                    Reset
                  </button>
                  <button class="btn btn-primary " @click="initTabulator">
                    Generate
                  </button>
                </div>
              </div>
              <div class="col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-3">
                <div class="flex flex-wrap">

                  <div class="text-sm font-bold mr-4">Total Stock: {{ page_data.total.total_packets ?
                    page_data.total.total_packets : 0 }}</div>
                  <div class="text-sm font-bold">Total WT(Ct): {{ page_data.total.total_weight ?
                    $h.myFloat(page_data.total.total_weight) : 0 }} Ct</div>
                </div>
              </div>
            </div>
          </div>
          <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
          </div>
        </div>
        <div v-if="page_data.form_status == true">

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-4 xl:col-span-4">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Scanned Pcs. :&nbsp;</label>
                  <label for="update-profile-form-6" class="form-label">{{ page_data.total_scan_pack }} </label>
                </div>
              </div>
              <div class="col-span-4 xl:col-span-4">
                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Pcs Weight. :&nbsp;</label>
                  <label for="update-profile-form-6" class="form-label">{{ $h.myFloat(page_data.total_scan_pack_wt) }}
                  </label>
                </div>
              </div>
              <div class="col-span-4 xl:col-span-4 text-right">
                <div>
                  <button class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="resetForm">
                    Reset Form
                  </button>
                  <button class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="removeGridRowSelect"
                    :disabled="page_data.delete_row">
                    Remove Selected Row
                  </button>
                </div>
              </div>
              <div class="col-span-12 ">
                <ag-grid-vue v-if="page_data.import_lab_type == 'mix'" class="ag-theme-alpine" style="width: 100%; height: 500px;" :columnDefs="columnDefsMix.value"
                  :rowData="rowData.value" :defaultColDef="defaultColDef" rowSelection="multiple"
                  :rowMultiSelectWithClick="true" animateRows="true" @grid-ready="onGridReady"
                  @selection-changed="onGridRowSelect" :overlayNoRowsTemplate="overlayNoRowsTemplate">
                </ag-grid-vue>
                <ag-grid-vue v-else class="ag-theme-alpine" style="width: 100%; height: 500px;" :columnDefs="columnDefs.value"
                  :rowData="rowData.value" :defaultColDef="defaultColDef" rowSelection="multiple"
                  :rowMultiSelectWithClick="true" animateRows="true" @grid-ready="onGridReady"
                  @selection-changed="onGridRowSelect" :overlayNoRowsTemplate="overlayNoRowsTemplate">
                </ag-grid-vue>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- Add Color modal-->

    <div id="success-notification-content" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Details saved successfully!</div>
        <div class="text-gray-600 mt-1">

        </div>
      </div>
    </div>
    <div id="failed-notification-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Something went wrong!</div>
        <div class="text-gray-600 mt-1">
          Please check the fileld form.
        </div>
      </div>
    </div>
    <div class="hidden">
      <input type="file" name="newExcelFile" id="newExcelFile" @change="getExcelData"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, inject, watch } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import {
  required,
  minLength,
  maxLength,
  email,
  url,
  integer
} from '@vuelidate/validators'
import { AgGridVue } from "ag-grid-vue3";
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import {
  BASE_URL,
  BASE_URL_SELLING,
  BUILD_ENV,
  Auth_Token
} from '@/constants/config.js'
import moment from 'moment'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import json5 from 'json5';
import VueBarcode from '@chenfengyuan/vue-barcode';
import {
  helper as $h
} from '@/utils/helper'
import FileDownload from "js-file-download";

export default defineComponent({
  components: {
    AgGridVue,
    ShapeRenderer
  },
  setup() {
    const gridApi = ref(null);
    const tableRef = ref()
    const tabulator = ref()

    class DatePicker {
      // gets called once before the renderer is used
      init(params) {
        // create the cell
        this.eInput = document.createElement('input');
        this.eInput.value = params.value;
        this.eInput.classList.add('ag-input');
        this.eInput.style.height = 'var(--ag-row-height)';
        this.eInput.style.fontSize = 'calc(var(--ag-font-size) + 1px)';

        // https://jqueryui.com/datepicker/
        $(this.eInput).datepicker({
          dateFormat: 'dd/mm/yy',
          onSelect: () => {
            this.eInput.focus();
          },
        });
      }

      // gets called once when grid ready to insert the element
      getGui() {
        return this.eInput;
      }

      // focus and select can be done after the gui is attached
      afterGuiAttached() {
        this.eInput.focus();
        this.eInput.select();
      }

      // returns the new value after editing
      getValue() {
        return this.eInput.value;
      }

      // any cleanup we need to be done here
      destroy() {
        // but this example is simple, no cleanup, we could
        // even leave this method out as it's optional
      }

      // if true, then this editor will appear in a popup
      isPopup() {
        // and we could leave this method out also, false is the default
        return false;
      }
    }

    const page_data = reactive({
      series_list: [],
      kapan_list: [],
      transaction_temp: [],
      form_status: false,
      excel_loading: false,
      submit: false,
      destroy: false,
      delete_row: false,
      lab_file: '',
      total_scan_pack: 0,
      total_scan_pack_wt: 0,
      return_progress:false,

      // Form
      kapan_details: [],
      total: [],
      heading_total: [],
      labs: [],
      transaction_form_list: [],

      import_lab_type: "",
    })

    const filter_data = reactive({
      kapan_id: '0',
      stock_no: '',
      packet_type: 'non-cert',
    })

    const AUTH_TOKEN = localStorage.getItem("token")

    const getRowId = (params) => {
      params.data.sr_no
      console.log(params.data.sr_no);
    }

    const onGridReady = (params) => {
      gridApi.value = params.api;
    };

    const rowData = reactive({
      value: []
    });

    const overlayNoRowsTemplate = 'Scan packets for process receive!'

    const columnDefs = reactive({
      value: [
        { headerName: "#", field: 'sr_no', maxWidth: 60, },
        { headerName: "Packet No", field: 'stock_no' },
        { headerName: "Weight", field: 'weight' },
        { headerName: "Color", field: 'color' },
        { headerName: "Clarity", field: 'clarity' },
        { headerName: "Cut", field: 'cut' },
        { headerName: "Polish", field: 'polish' },
        { headerName: "Symmetry", field: 'symmetry' },
        { headerName: "Fluorescence Intensity", field: 'fluorescence_intensity' },
        { headerName: "Fluorescence Color", field: 'fluorescence_color' },
        { headerName: "Measurements", field: 'measurements' },
        { headerName: "Shade", field: 'shade' },
        { headerName: "Milky", field: 'milky' },
        { headerName: "Growth Type", field: 'growth_type' },
        { headerName: "Lab", field: 'lab' },
        { headerName: "Report", field: 'report' },
        { headerName: "Report Issue Date", field: 'report_issue_date' },
        { headerName: "Location", field: 'location' },
        { headerName: "Treatment", field: 'treatment' },
        { headerName: "Padtar Per Carat", field: 'padtar_per_carat' },
        { headerName: "Padtar Discount", field: 'padtar_discount' },
        { headerName: "Price Per Carat", field: 'price_per_carat' },
        { headerName: "Discount", field: 'discount' },
        { headerName: "Depth", field: 'depth' },
        { headerName: "Table", field: 'table' },
        { headerName: "Girdle Thin", field: 'girdle_thin' },
        { headerName: "Girdle Thick", field: 'girdle_thick' },
        { headerName: "Girdle Percent", field: 'girdle_percent' },
        { headerName: "Girdle Condition", field: 'girdle_condition' },
        { headerName: "Culet Size", field: 'culet_size' },
        { headerName: "Culet Condition", field: 'culet_condition' },
        { headerName: "Crown Height", field: 'crown_height' },
        { headerName: "Crown Angle", field: 'crown_angle' },
        { headerName: "Pavilion Depth", field: 'pavilion_depth' },
        { headerName: "Pavilion Angle", field: 'pavilion_angle' },
        { headerName: "Star Length", field: 'star_length' },
        { headerName: "Inscription", field: 'inscription' },
        { headerName: "Laser Inscription", field: 'laser_inscription' },
        { headerName: "Cert Comment", field: 'cert_comment' },
        { headerName: "KeyToSymbols", field: 'keyToSymbols' },
        { headerName: "White Inclusion", field: 'white_inclusion' },
        { headerName: "Black Inclusion", field: 'black_inclusion' },
        { headerName: "Open Inclusion", field: 'open_inclusion' },
        { headerName: "Fancy Color", field: 'fancy_color' },
        { headerName: "Fancy Color Intensity", field: 'fancy_color_intensity' },
        { headerName: "Fancy Color Overtone", field: 'fancy_color_overtone' },
        { headerName: "Country", field: 'country' },
        { headerName: "State", field: 'state' },
        { headerName: "City", field: 'city' },
        { headerName: "Certi Link", field: 'certi_link' },
        { headerName: "Diamond Video", field: 'diamond_video' },
        { headerName: "Diamond Image", field: 'diamond_image' },
        { headerName: "Heart Image", field: 'heart_image' },
        { headerName: "Arrow Image", field: 'arrow_mage' },
        { headerName: "Asset Image", field: 'asset_image' },
        { headerName: "Luster", field: 'luster' },
        { headerName: "Brand", field: 'brand' },
        { headerName: "Origin", field: 'origin' },
        { headerName: "Syndication", field: 'syndication' },
        { headerName: "Producing Lab", field: 'producing_lab' },
        { headerName: "Growth Type", field: 'growth_type' },
        { headerName: "Tinge Hue", field: 'tinge_hue' },
        { headerName: "Sarine Loupe", field: 'sarine_loupe' },
        { headerName: "External Video", field: 'external_video' }
      ],
    });

    const columnDefsMix = reactive({
      value: [
        { headerName: "#", field: 'sr_no', maxWidth: 60, },
        { headerName: "Packet No", field: 'packet_no' },
        { headerName: "Stock No", field: 'stock_no' },
        { headerName: "Size", field: 'size' },
        { headerName: "Shape", field: 'shape' },
        { headerName: "Pieces", field: 'pieces' },
        { headerName: "Weight", field: 'weight' },
        { headerName: "Color", field: 'color' },
        { headerName: "Clarity", field: 'clarity' },
        { headerName: "Cut", field: 'cut' },
        { headerName: "Polish", field: 'polish' },
        { headerName: "Symmetry", field: 'symmetry' },
        { headerName: "PadtarPer Ct", field: 'padtar_per_ct' },
        { headerName: "Per Ct", field: 'per_ct' },
        { headerName: "Growth Type", field: 'stone_type' }
      ],
    });

    const defaultColDef = {
      sortable: true,
      filter: true,
      minWidth: 150,
      //  floatingFilter: true,
      flex: 1
    };
    const swal = inject('$swal')
    const initTabulator = async () => {

      let filter_params = {
        kapan_id: filter_data.kapan_id == '0' ? '' : window.btoa(filter_data.kapan_id),
        stock_no: filter_data.stock_no == '' ? '' : window.btoa(filter_data.stock_no),
        packet_type: window.btoa(filter_data.packet_type)
      }

      if(filter_data.packet_type == 'non-cert') {

        tabulator.value = new Tabulator(tableRef.value, {
          ajaxURL: BASE_URL + 'api/final-polish/lsc-grs',
          ajaxConfig: {
            method: "GET", //set request type to Position
            headers: {
              "Content-type": 'application/json; charset=utf-8', //set specific content type
              "Authorization": "Bearer " + localStorage.getItem("token")
            },
          },
          ajaxParams: filter_params,
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          headerSort: false,
          pagination: 'remote',
          paginationSize: 20,
          paginationSizeSelector: [20, 30, 40],
          layout: 'fitColumns',
          // responsiveLayout: 'collapse',
          placeholder: 'No matching records found',
          columns: [
            // {
            //       formatter: 'responsiveCollapse',
            //       width: 40,
            //       minWidth: 30,
            //       hozAlign: 'center',
            //       resizable: false,
            //       headerSort: false
            //   },

            // For HTML table
            {
              title: 'Packet No',
              responsive: 0,
              minWidth: 130,
              field: 'number',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                    <div class="whitespace-nowrap">${cell.getData().urgent == 1 ? '<span class="text-theme-6">' + cell.getData().number + '</span>' : cell.getData().number
                  }</div>
                  </div>`
              }
            },
            {
              title: 'Weight(Ct)',
              responsive: 0,
              field: 'cr_weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 130,
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                    <div class="whitespace-nowrap">${cell.getData().cr_weight
                  }</div>
                  </div>`
              }
            },
            {
              title: 'Shp',
              field: 'shape',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Clr',
              field: 'color',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Prt',
              field: 'purity',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Ct',
              field: 'cut',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Pl',
              field: 'polish',
              hozAlign: 'left',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Sym',
              field: 'symmetry',
              hozAlign: 'left',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
          ],
          renderComplete() {
            feather.replace({
              'stroke-width': 1.5
            })
          }
        })
      } else if(filter_data.packet_type == 'mix') {

tabulator.value = new Tabulator(tableRef.value, {
  ajaxURL: BASE_URL + 'api/final-polish/lsc-grs',
  ajaxConfig: {
    method: "GET", //set request type to Position
    headers: {
      "Content-type": 'application/json; charset=utf-8', //set specific content type
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  },
  ajaxParams: filter_params,
  ajaxFiltering: true,
  ajaxSorting: true,
  printAsHtml: true,
  printStyled: true,
  headerSort: false,
  pagination: 'remote',
  paginationSize: 20,
  paginationSizeSelector: [20, 30, 40],
  layout: 'fitColumns',
  // responsiveLayout: 'collapse',
  placeholder: 'No matching records found',
  columns: [
    // {
    //       formatter: 'responsiveCollapse',
    //       width: 40,
    //       minWidth: 30,
    //       hozAlign: 'center',
    //       resizable: false,
    //       headerSort: false
    //   },

    // For HTML table
    {
      title: 'Packet No',
      responsive: 0,
      minWidth: 130,
      field: 'number',
      vertAlign: 'middle',
      print: false,
      download: false,
      formatter(cell) {
        return `<div>
            <div class="whitespace-nowrap">${cell.getData().urgent == 1 ? '<span class="text-theme-6">' + cell.getData().number + '</span>' : cell.getData().number
          }</div>
          </div>`
      }
    },
    {
      title: 'Pieces',
      responsive: 0,
      field: 'pieces',
      hozAlign: 'center',
      vertAlign: 'middle',
      minWidth: 130,
      print: false,
      download: false,
      formatter(cell) {
        return `<div>
            <div class="whitespace-nowrap">${cell.getData().pieces
          }</div>
          </div>`
      }
    },
    {
      title: 'Weight(Ct)',
      responsive: 0,
      field: 'cr_weight',
      hozAlign: 'center',
      vertAlign: 'middle',
      minWidth: 130,
      print: false,
      download: false,
      formatter(cell) {
        return `<div>
            <div class="whitespace-nowrap">${cell.getData().cr_weight
          }</div>
          </div>`
      }
    },
    {
      title: 'Shp',
      field: 'shape',
      hozAlign: 'center',
      vertAlign: 'middle',
      minWidth: 90,
      print: false,
      download: false
    },
    {
      title: 'Clr',
      field: 'color',
      hozAlign: 'center',
      vertAlign: 'middle',
      minWidth: 90,
      print: false,
      download: false
    },
    {
      title: 'Prt',
      field: 'purity',
      hozAlign: 'center',
      vertAlign: 'middle',
      minWidth: 90,
      print: false,
      download: false
    },
    {
      title: 'Ct',
      field: 'cut',
      hozAlign: 'center',
      vertAlign: 'middle',
      minWidth: 90,
      print: false,
      download: false
    },
    {
      title: 'Pl',
      field: 'polish',
      hozAlign: 'left',
      vertAlign: 'middle',
      minWidth: 90,
      print: false,
      download: false
    },
    {
      title: 'Sym',
      field: 'symmetry',
      hozAlign: 'left',
      vertAlign: 'middle',
      minWidth: 90,
      print: false,
      download: false
    },
  ],
  renderComplete() {
    feather.replace({
      'stroke-width': 1.5
    })
  }
})
} else {

        tabulator.value = new Tabulator(tableRef.value, {
          ajaxURL: BASE_URL + 'api/final-polish/lsc-grs',
          ajaxConfig: {
            method: "GET", //set request type to Position
            headers: {
              "Content-type": 'application/json; charset=utf-8', //set specific content type
              "Authorization": "Bearer " + localStorage.getItem("token")
            },
          },
          ajaxParams: filter_params,
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          headerSort: false,
          pagination: 'remote',
          paginationSize: 20,
          paginationSizeSelector: [20, 30, 40],
          layout: 'fitColumns',
          // responsiveLayout: 'collapse',
          placeholder: 'No matching records found',
          columns: [
            // {
            //       formatter: 'responsiveCollapse',
            //       width: 40,
            //       minWidth: 30,
            //       hozAlign: 'center',
            //       resizable: false,
            //       headerSort: false
            //   },

            // For HTML table
            {
              title: 'Packet No',
              responsive: 0,
              minWidth: 130,
              field: 'number',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                    <div class="whitespace-nowrap">${cell.getData().urgent == 1 ? '<span class="text-theme-6">' + cell.getData().number + '</span>' : cell.getData().number
                  }</div>
                  </div>`
              }
            },
            {
              title: 'LAB',
              responsive: 0,
              field: 'lab_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 100,
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${cell.getData().lab_name
                  }</div>
                </div>`
              }
            },
            {
              title: 'Report No',
              responsive: 0,
              field: 'type',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 130,
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${cell.getData().report_number
                  }</div>
                </div>`
              }
            },
            {
              title: 'Weight(Ct)',
              responsive: 0,
              field: 'cr_weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 130,
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                    <div class="whitespace-nowrap">${cell.getData().cr_weight
                  }</div>
                  </div>`
              }
            },
            {
              title: 'Exp. Wt',
              responsive: 0,
              field: 'cr_polish_weight',
              minWidth: 130,
              print: false,
              download: false
            },
            {
              title: 'Shp',
              field: 'shape',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Clr',
              field: 'color',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Prt',
              field: 'purity',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Ct',
              field: 'cut',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Pl',
              field: 'polish',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Sym',
              field: 'symmetry',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            }
          ],
          renderComplete() {
            feather.replace({
              'stroke-width': 1.5
            })
          }
        })
      }

      let promise = axios({
        url: "api/final-polish/lsc-lab-grst",
        method: "post",
        data: filter_params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),

        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data);
          page_data.total = data.total
        })
        .catch(_error => {
          page_data.total = []
        });
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    const takeMultiReturn = () => {
      gridApi.value.stopEditing();
      if(rowData.value.length == 0){
        swal("Error!", "Kindly select packets for return, Packet count is zero", "error");
        return;
      }

      let total_pcs = rowData.value.length;
      let i = 0;
      // while(i < total_pcs){
      //   console.log(rowData.value[i].cr_wt);
      //   if(rowData.value[i].cr_wt == '' || rowData.value[i].cr_wt == undefined){
      //     swal("Error!", "Kindly Enter Return Weight to all packet and retry!", "error");
      //     return;
      //   }
      //   i++;
      // }

      // console.log(rowData.value);

      let api_url = "api/final-polish/lsc-update-final-stock"
      // let api_url = "api/return_lab_rate_packets"


      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You are about to take update entry!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          const data = {
            packet_details: rowData.value,
            stock_type: page_data.import_lab_type
          };

          let promise = axios({
            url: api_url,
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),

            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {

              if(data.status == "success"){
                if(data.type == "success") {

                  swal.fire({
                    icon:'success',
                    title:"Success!",
                    text:data.message,
                    showConfirmButton: false,
                    timer:1500
                  })

                  resetData()
                  // swal("Success!", data.message, "success");
                } else {
                  swal("Warning!", data.message, "warning");
                }

              }else if(data.status == "error"){
                swal("Error!", data.message , "error");
                screenData.return_progress = false;
                return;
              }
            })
            .catch(_error => {
              screenData.return_progress = false;
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          screenData.return_progress = false;
        }
      });
    }


    const getLotDetails = () => {
      axios({
        url: 'api/master/dp_lot_details',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        page_data.kapan_details = res.data.items
      })
    }

    // On reset filter
    const onResetFilter = () => {
      filter_data.packet_type = 'non-cert'
      filter_data.kapan_id = '0'
      filter_data.stock_no = ''

      initTabulator()
    }

    const resetForm = () => {

      rowData.value = []
      gridApi.value.setRowData(rowData.value);
      page_data.total_scan_pack_wt = 0;
      page_data.form_status = false;
      page_data.excel_loading = false;
      page_data.total_scan_pack = 0;

      setTimeout(() => {
        initTabulator()
      }, 200);
    }

    const get_heading_total = () => {
      // lsc-gsth
      let promise = axios({
        url: "api/final-polish/lsc-glsth",
        method: "post",
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),

        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data);
          page_data.heading_total = data.total
        })
        .catch(_error => {
          page_data.heading_total = []
        })
    }

    const exportCurrentStocks = () => {

      let filename = "lab-stock-rate.xlsx";
      // Initialize Array for Select Form
      let temp_arr = {
        'non-cert': "Non Certified",
        'mix': "Mix Packet"
      }

      page_data.labs.forEach(element => {
        temp_arr[element.id] = element.lab_name
      });
      // temp_arr["all"] = "All"

      swal.fire({
        title: "Are you sure?",
        text: "You are about to take stock excel!",
        type: "warning",
        input: "select",
        inputOptions: {
          "LAB Type": temp_arr,
        },
        inputValidator: (value) => {
          if (!value) {
            return "Please, select stone type first!";
          }
        },
        inputBorder: "2px solid blue",
        inputPlaceholder: "Select a stone type",
        showCancelButton: true,
        confirmButtonText: "Export File",
        showLoaderOnConfirm: true,
        preConfirm: async (login) => {

          console.log(login)
          try {
            let params = {
              packet_type: btoa(login)
            }
            let promise = axios({
              url: "api/final-polish/lsc-final-stock-export",
              method: "post",
              data: params,
              baseURL: BASE_URL,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),

              },
              config: {
                headers: {
                  Accept: "application/json"
                }
              },
              responseType: "blob",
            });

            return promise
              .then((result) => result.data)
              .then((data) => {
                return data;
              })
              .catch((_error) => {
                return [];
              });
          } catch (error) {
            // Swal.showValidationMessage(`
            //   Request failed: ${error}
            // `);
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire({
          //   title: `${result.value.login}'s avatar`,
          //   imageUrl: result.value.avatar_url
          // });



          console.log(result)
          FileDownload(result.value, filename);
        }
      });

      // export stocks

      // lsuc_export
      // let filename = "lab-stock-rate.xlsx";
      // let params = {}
      // let promise = axios({
      //   url: "api/final-polish/lsc-final-stock-export",
      //   method: "post",
      //   data: params,
      //   baseURL: BASE_URL,
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
//
      //   },
      //   config: {
      //     headers: {
      //       Accept: "application/json"
      //     }
      //   },
      //   responseType: "blob",
      // });

      // return promise
      //   .then((result) => result.data)
      //   .then((data) => {
      //     FileDownload(data, filename);
      //   })
      //   .catch((_error) => {
      //     return [];
      //   });
    }
    const inputFileClick = () => {
      let filename = "lab-stock-rate.xlsx";
      // Initialize Array for Select Form
      let temp_arr = {
        'non-cert': "Non Certified",
        'mix': "Mix Packet"
      }

      page_data.labs.forEach(element => {
        temp_arr[element.id] = element.lab_name
      });
      // temp_arr["all"] = "All"

      swal.fire({
        title: "Are you sure?",
        text: "You are about to update stock excel!",
        type: "warning",
        input: "select",
        inputOptions: {
          "LAB Type": temp_arr,
        },
        inputValidator: (value) => {
          if (!value) {
            return "Please, select stone type first!";
          }
        },
        inputBorder: "2px solid blue",
        inputPlaceholder: "Select a stone type",
        showCancelButton: true,
        confirmButtonText: "Select File",
        showLoaderOnConfirm: true,
        preConfirm: async (login) => {

          page_data.import_lab_type = login

          document.getElementById("newExcelFile").click()

          // console.log(login)
          // try {
          //   let params = {
          //     packet_type: btoa(login)
          //   }
          //   let promise = axios({
          //     url: "api/final-polish/lsc-final-stock-export",
          //     method: "post",
          //     data: params,
          //     baseURL: BASE_URL,
          //     headers: {
          //       Authorization: "Bearer " + localStorage.getItem("token"),
//
          //     },
          //     config: {
          //       headers: {
          //         Accept: "application/json"
          //       }
          //     },
          //     responseType: "blob",
          //   });

          //   return promise
          //     .then((result) => result.data)
          //     .then((data) => {
          //       return data;
          //     })
          //     .catch((_error) => {
          //       return [];
          //     });
          // } catch (error) {
          //   // Swal.showValidationMessage(`
          //   //   Request failed: ${error}
          //   // `);
          // }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire({
          //   title: `${result.value.login}'s avatar`,
          //   imageUrl: result.value.avatar_url
          // });



          // console.log(result)
          // FileDownload(result.value, filename);
        }
      });
    }


    const getExcelData = (e) => {
      console.log(e.target.files[0]);
      page_data.lab_file = e.target.files[0];

      e.preventDefault();

      if (page_data.lab_file) {

        page_data.excel_loading = true

        let formData = new FormData();
        formData.append('scanned_data', JSON.stringify(rowData.value));
        formData.append('lab_data', page_data.lab_file);
        formData.append('packet_type', btoa(page_data.import_lab_type));

        let promise = axios({
          url: "api/final-polish/lsc-read-final-stock",
          method: "POST",
          data: formData,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
          config: { headers: { Accept: "application/json" } },
          'content-type': 'multipart/form-data'
        });
        return promise
          .then(result => result.data)
          .then(data => {
            if (data.error == "YES") {
              page_data.form_status = false
              page_data.excel_loading = false
              swal('Error!', data.message, "error");
              return
            } else {

              page_data.form_status = true
              page_data.excel_loading = false

              let new_items = data.items


              if(new_items.length > 0) {

                if(page_data.import_lab_type == "mix") {

                  new_items.forEach(element => {
                    rowData.value.push(
                      {
                        "sr_no": page_data.total_scan_pack + 1,
                        "id": element.id,
                        "packet_no": element.packet_no,
                        "stock_no": element.stock_no,
                        "size": element.size,
                        "shape": element.shape,
                        "pieces": element.pieces,
                        "weight": element.weight,
                        "color": element.color,
                        "clarity": element.clarity,
                        "cut": element.cut,
                        "polish": element.polish,
                        "symmetry": element.symmetry,
                        "padtar_per_ct": element.padtar_per_ct,
                        "per_ct": element.per_ct,
                        "stone_type": element.stone_type
                      });

                    reCaclulateTotal();
                  });
                } else {

                  new_items.forEach(element => {
                    rowData.value.push(
                      {
                        "sr_no": page_data.total_scan_pack + 1,
                        "id": element.id,
                        "packet_no": element.packet_no,
                        "barcode": element.barcode,
                        "availability": element.availability,
                        "shape": element.shape,
                        "weight": element.weight,
                        "color": element.color,
                        "clarity": element.clarity,
                        "cut": element.cut,
                        "polish": element.polish,
                        "symmetry": element.symmetry,
                        "fluorescence_intensity": element.fluorescence_intensity,
                        "fluorescence_color": element.fluorescence_color,
                        "measurements": element.measurements,
                        "shade": element.shade,
                        "milky": element.milky,
                        "eye_clean": element.eye_clean,
                        "lab": element.lab,
                        "report": element.report,
                        "report_issue_date": element.report_issue_date,
                        "location": element.location,
                        "treatment": element.treatment,
                        "padtar_per_carat": element.padtar_per_carat,
                        "padtar_discount": element.padtar_discount,
                        "price_per_carat": element.price_per_carat,
                        "discount": element.discount,
                        "depth": element.depth,
                        "table": element.table,
                        "girdle_thin": element.girdle_thin,
                        "girdle_thick": element.girdle_thick,
                        "girdle_percent": element.girdle_percent,
                        "girdle_condition": element.girdle_condition,
                        "culet_size": element.culet_size,
                        "culet_condition": element.culet_condition,
                        "crown_height": element.crown_height,
                        "crown_angle": element.crown_angle,
                        "pavilion_depth": element.pavilion_depth,
                        "pavilion_angle": element.pavilion_angle,
                        "star_length": element.star_length,
                        "inscription": element.inscription,
                        "laser_inscription": element.laser_inscription,
                        "cert_comment": element.cert_comment,
                        "keyToSymbols": element.keyToSymbols,
                        "white_inclusion": element.white_inclusion,
                        "black_inclusion": element.black_inclusion,
                        "open_inclusion": element.open_inclusion,
                        "fancy_color": element.fancy_color,
                        "fancy_color_intensity": element.fancy_color_intensity,
                        "fancy_color_overtone": element.fancy_color_overtone,
                        "country": element.country,
                        "state": element.state,
                        "city": element.city,
                        "certi_link": element.certi_link,
                        "diamond_video": element.diamond_video,
                        "diamond_image": element.diamond_image,
                        "heart_image": element.heart_image,
                        "arrow_mage": element.arrow_mage,
                        "asset_image": element.asset_image,
                        "luster": element.luster,
                        "brand": element.brand,
                        "origin": element.origin,
                        "syndication": element.syndication,
                        "producing_lab": element.producing_lab,
                        "growth_type": element.growth_type,
                        "tinge_hue": element.tinge_hue,
                        "sarine_loupe": element.sarine_loupe,
                        "external_video": element.external_video,
                        "stone": element.stone,
                      });

                    reCaclulateTotal();
                  });
                }


                gridApi.value.setRowData(rowData.value);
              }
              if (data.message != '') {

                swal('Warning!', data.message, "warning");
              }

              page_data.lab_file = ''
              document.querySelector('[id="newExcelFile"]').value = '';
            }


          })
          .catch(_error => {

            page_data.excel_loading = false
          });
      }

    }
    const reCaclulateTotal = () => {
      var j = rowData.value.length;
      var total_pc = 0;
      var total_ct = 0;
      var sr = 1;
      while (j--) {
        rowData.value[j]['sr_no'] = j + 1;

        if(page_data.import_lab_type == "mix") {

          total_pc += parseFloat(rowData.value[j]['pieces']);
          total_ct = total_ct + parseFloat(rowData.value[j]['weight']);
        } else {

          total_pc++;
          total_ct = total_ct + parseFloat(rowData.value[j]['cr_wt']);
          // rowData.value[j]['sr_no'] = j + 1;
        }
        sr++;
      }

      page_data.total_scan_pack_wt = total_ct;
      page_data.total_scan_pack = total_pc;

    }
    const onGridRowSelect = () => {
      console.log(gridApi.value.getSelectedRows().length);
      if (gridApi.value.getSelectedRows().length == 0) {
        page_data.delete_row = true;
      } else {
        page_data.delete_row = false;
      }
    }
    const resetData = () => {
      page_data.form_status = false
      page_data.excel_loading = false
      rowData.value = []
      gridApi.value.setRowData(rowData.value);
      // page_data.total_scan_pack_wt = 0;
      // page_data.total_scan_pack = 0;
    }
    const importCurrentStocks = () => {

      // import stocks
    }

    const getDiamontData = () => {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept': 'application/json' } }
      }).then((res) => {
        page_data.labs = res.data.lab
      })
    }

    onMounted(() => {
      initTabulator()
      getDiamontData()
      getLotDetails()
      get_heading_total()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      page_data,
      filter_data,
      onResetFilter,
      initTabulator,
      resetForm,
      exportCurrentStocks,
      importCurrentStocks,
      getExcelData,
      inputFileClick,
      defaultColDef,
      onGridReady,
      onGridRowSelect,
      overlayNoRowsTemplate,
      columnDefsMix,
      columnDefs,
      rowData,
      gridApi,
      takeMultiReturn
    }
  }
})
</script>
