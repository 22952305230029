<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Transaction Details</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
            <button @click="print_jangad" class="btn btn-primary btn-sm shadow-md mr-2">
                <PrinterIcon class="w-4 h-4" /></button>
        </div>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
            <button @click="export_stock" class="btn btn-primary btn-sm shadow-md mr-2">Export</button>
        </div>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0" v-if="transaction_details != null && (transaction_details.issue_by == user_details.id && ((transaction_details.transaction_type == 1 && transaction_details.status == 1) || (transaction_details.transaction_type == 2 && (transaction_details.receive_pieces == 0 || transaction_details.receive_pieces == null))))">
            <button @click="show_model" class="btn btn-primary btn-sm shadow-md mr-2">Edit Jangad</button>
        </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">

            <!-- BEGIN: Input -->
            <div class="intro-y box" v-if="transaction_details != null">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5" v-if="transaction_details.transaction_status == 1 && transaction_details.status == 1 && (transaction_details.transaction_type == 1 || transaction_details.transaction_type == 4 || transaction_details.transaction_type == 3 || transaction_details.process_type == 3 || transaction_details.process_type == 4)">
                    <h2 class="font-medium text-base mr-auto">Transaction Details</h2>
                    <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0" v-if="transaction_details.issue_by == user_details.id">
                        <button class="btn btn-primary shadow-md mr-2" @click="editTransactionStatus(transaction_details.id,transaction_details.issue_by,'withdraw');">
                            Withdraw
                        </button>
                    </div>
                    <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0" v-else>
                        <button class="btn btn-primary shadow-md mr-2" @click="editTransactionStatus(transaction_details.id,transaction_details.issue_by,'accept');">
                            Accept
                        </button>
                    </div>
                </div>
                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-5">
                        <div class="col-span-4 xl:col-span-4">

                            <div>
                                <label for="update-profile-form-6" class="form-label font-bold">Transaction No. :&nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.transaction_code }}</label>
                            </div>

                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label font-bold">Issue Pieces : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.pieces }}</label>
                            </div>
                            <div class="mt-3" v-if="transaction_details.receive_pieces">
                                <label for="update-profile-form-6" class="form-label font-bold">Receive Pieces : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.receive_pieces }}</label>
                            </div>
                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label font-bold">Issue Weight : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(transaction_details.issue_weight) }} (Ct)</label>
                            </div>
                            <div class="mt-3" v-if="transaction_details.receive_weight">
                                <label for="update-profile-form-6" class="form-label font-bold">Receive Weight : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ $h.myFloat(transaction_details.receive_weight) }} (Ct)</label>
                            </div>
                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label font-bold">Issue For : &nbsp;</label><label for="update-profile-form-6" class="form-label">
                                    <span v-if="transaction_details.transaction_type == 1">Manager Transfer</span>
                                    <span v-if="transaction_details.transaction_type == 4">Job Done Transfer</span>
                                    <span v-if="transaction_details.transaction_type == 2">Process</span>
                                    <span v-if="transaction_details.transaction_type == 3">Process Done Transfer</span>
                                </label>
                            </div>

                        </div>
                        <div class="col-span-4 xl:col-span-4">

                            <div class="" v-if="transaction_details.transaction_type == 2">
                                <label for="update-profile-form-6" class="form-label font-bold">Process Name : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.process_name }}</label>
                            </div>
                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label font-bold">Worker Name : &nbsp;</label><label for="update-profile-form-6" class="form-label">
                                    <span v-if="user_details.id == transaction_details.issue_by">
                                        <span v-if="transaction_details.transaction_type == 1 || transaction_details.transaction_type == 3 || transaction_details.transaction_type == 4">{{ transaction_details.issue_manager_first_name+ ' ' + transaction_details.issue_manager_last_name }}</span>
                                        <span v-else-if="transaction_details.transaction_type == 2">
                                            <span v-if="transaction_details.process_type == 1 || transaction_details.process_type == 2 || transaction_details.process_type == 5 || transaction_details.process_type == 6 || transaction_details.process_type == 7 || transaction_details.process_type == 8">
                                                <span v-if="transaction_details.employee_role == 7">{{ (transaction_details.karigar_first_name ? transaction_details.karigar_first_name : '') + ' ' + (transaction_details.karigar_last_name ? transaction_details.karigar_last_name : '') }}</span>
                                                <span v-if="transaction_details.employee_role == 4">{{ (transaction_details.pp_first_name ? transaction_details.pp_first_name : '') + ' ' + (transaction_details.pp_last_name ? transaction_details.pp_last_name : '') }}</span>
                                            </span>
                                            <span v-if="transaction_details.process_type == 3 || transaction_details.process_type == 4">
                                                <span>{{ (transaction_details.planner_first_name ? transaction_details.planner_first_name : '') + ' ' + (transaction_details.planner_last_name ? transaction_details.planner_last_name : '') }}</span>
                                            </span>
                                        </span>
                                    </span>
                                    <span v-else>
                                        <span>{{ (transaction_details.manager_first_name ? transaction_details.manager_first_name : '') + ' ' + (transaction_details.manager_last_name ? transaction_details.manager_last_name : '') }}</span>
                                    </span>
                                </label>
                            </div>
                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label font-bold">Manager Name : &nbsp;</label><label for="update-profile-form-6" class="form-label">

                                    <span v-if="user_details.id != transaction_details.issue_by && (user_details.user_role == 2 || user_details.user_role == 8 || user_details.user_role == 1)"> {{ transaction_details.manager_first_name }} {{ transaction_details.manager_last_name }} </span>
                                    <span v-else> {{ (transaction_details.issue_manager_first_name ? transaction_details.issue_manager_first_name : '')+ ' ' + (transaction_details.issue_manager_last_name ? transaction_details.issue_manager_last_name : '') }} </span>
                                </label>
                            </div>
                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label font-bold">Issue Date Time : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ moment(transaction_details.created_at).format('DD/MM/YYYY hh:mm:ss') }}</label>
                            </div>
                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label font-bold">Receive Date Time : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.receive_date ? moment(transaction_details.receive_date).format('DD/MM/YYYY hh:mm:ss') : '-' }}</label>
                            </div>
                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label font-bold">Status : &nbsp;</label>
                                <label for="update-profile-form-6" class="form-label">
                                    <span v-if="transaction_details.transaction_type == 1 || transaction_details.transaction_type == 4 || transaction_details.transaction_type == 3 || (transaction_details.transaction_type == 2 && (transaction_details.process_type  == 5 || transaction_details.process_type  == 6))">
                                        <span v-if="transaction_details.status == 1 && transaction_details.transaction_status == 1" class="text-theme-11 font-bold">Hold</span>
                                        <span v-if="transaction_details.status == 1 && transaction_details.transaction_status == 2" class="text-theme-9 font-bold">Accepted</span>
                                        <span v-if="transaction_details.status == 3 || transaction_details.transaction_status == 3" class="text-theme-6 font-bold">Cancelled</span>

                                    </span>
                                    <span v-else>
                                        <span v-if="transaction_details.status == 1 && transaction_details.transaction_status == 2" class="text-theme-9 font-bold">In Process</span>
                                        <span v-if="transaction_details.status == 2 && transaction_details.transaction_status == 2" class="text-theme-6 font-bold">Received</span>
                                        <span v-if="transaction_details.status == 2 && transaction_details.transaction_status == 1" class="text-theme-6 font-bold">Received</span>
                                        <span v-if="transaction_details.status == 3 || transaction_details.transaction_status == 3" class="text-theme-6 font-bold">Cancelled</span>
                                    </span>
                                </label>
                            </div>

                        </div>
                        <div class="col-span-4 xl:col-span-4">

                            <div class="">
                                <label for="update-profile-form-6" class="form-label font-bold">Issue IP : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.issue_trans_ip }}</label>
                            </div>
                            <div class="mt-3">
                                <label for="update-profile-form-6" class="form-label font-bold">Edit IP : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.edit_trans_ip }}</label>
                            </div>
                            <div class="mt-3" v-if="transaction_details.transaction_status == 3">
                                <label for="update-profile-form-6" class="form-label font-bold">Cancel IP : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.withdrawal_trans_ip }}</label>
                            </div>
                            <div class="mt-3" v-if="transaction_details.transaction_type == 1 ||  transaction_details.transaction_type == 4">
                                <label for="update-profile-form-6" class="form-label font-bold">Accept : &nbsp;</label><label for="update-profile-form-6" class="form-label"> {{ transaction_details.accept_trans_ip }}</label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="intro-y box mt-10" v-if="transaction_details != null">
                <div class="col-span-12 xl:col-span-12">
                    <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
                        <h2 class="font-medium text-base mr-auto pl-5">Selected Packets</h2>
                    </div>
                    <div class="p-5">
                        <div class="grid grid-cols-12 gap-x-5">
                            <div class="col-span-12">
                                <div class="border">
                                    <div class="grid grid-cols-12 sticky top-0 pl-5 pr-5 pb-2 mt-3" style="background-color:#fff;">

                                        <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-left">
                                            <p>Sr. No</p>
                                        </div>
                                        <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2 text-center">
                                            <p>Transaction</p>
                                        </div>
                                        <div class="col-span-6 md:col-span-2 font-bold xl:col-span-2 text-center">
                                            <p>Packet</p>
                                        </div>
                                        <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                                            <p>Stock No</p>
                                        </div>
                                        <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                                            <p>Type</p>
                                        </div>
                                        <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                                            <p>Issue WT(Ct)</p>
                                        </div>
                                        <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                                            <p>Polish WT(Ct)</p>
                                        </div>
                                        <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center" v-if="transaction_details.process_type == 6">
                                            <p>Polish Party</p>
                                        </div>
                                        <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                                            <p>Return Date</p>
                                        </div>
                                        <div class="col-span-6 md:col-span-1 font-bold xl:col-span-1 text-center">
                                            <p>Status</p>
                                        </div>
                                    </div>

                                    <div class="border" v-for="(field,index) in transaction_packets" :key="index">
                                        <div class="grid grid-cols-12">
                                            <div class="col-span-6 md:col-span-1 xl:col-span-1 p-1 text-left">
                                                <label class="p-2 mt-2">{{index+1}}</label>
                                            </div>
                                            <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2 text-center">
                                                <label class="p-2 mt-3">{{field.transaction_code}}</label>
                                            </div>
                                            <div class="col-span-6 md:col-span-2 xl:col-span-2 p-2 text-center">
                                                <label class="p-2 mt-3">{{field.number}}</label>
                                            </div>
                                            <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center">
                                                <label class="p-2 mt-3">{{field.vepari_no}}</label>
                                            </div>
                                            <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center">
                                                <label class="p-2 mt-3">{{field.packet_type}}</label>
                                            </div>
                                            <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center">
                                                <label class="p-2 mt-3">{{field.issue_weight ? $h.myFloat(field.issue_weight) : '0'}} Ct</label>
                                            </div>
                                            <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center">
                                                <label class="p-2 mt-3">{{field.cr_polish_weight ? $h.myFloat(field.cr_polish_weight) : '0'}} Ct</label>
                                            </div>
                                            <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center" v-if="transaction_details.process_type == 6">
                                                <label class="p-2 mt-3">{{field.pp_company_name ? field.pp_company_name : (field.pp_first_name + ' ' + field.pp_last_name)}} </label>
                                            </div>
                                            <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-center">
                                                <label class="p-2 mt-3">{{(field.receive_date ? moment(field.receive_date).format('D/M/YYYY hh:mm:ss') : '-')}}</label>
                                            </div>
                                            <div class="col-span-6 md:col-span-1 xl:col-span-1 p-2 text-left">
                                                <label class="p-2 mt-3">
                                                    <span v-if="field.transaction_type == 1 || field.transaction_type == 4 || field.transaction_type == 3">
                                                        <span v-if="field.status == 1 && field.transaction_status == 1" class="text-theme-11 font-bold">Hold</span>
                                                        <span v-if="field.status == 1 && field.transaction_status == 2" class="text-theme-9 font-bold">Accepted</span>
                                                        <span v-if="field.status == 2 && field.transaction_status == 2" class="text-theme-9 font-bold">Accepted</span>
                                                        <span v-if="field.status == 3 || field.transaction_status == 3" class="text-theme-6 font-bold">Cancelled</span>

                                                    </span>
                                                    <span v-else>
                                                        <span v-if="field.status == 1 && field.transaction_status == 1" class="text-theme-11 font-bold">Hold</span>
                                                        <span v-if="field.status == 1 && field.transaction_status == 2" class="text-theme-9 font-bold">In Process</span>
                                                        <span v-if="field.status == 2 && field.transaction_status == 2" class="text-theme-6 font-bold">Received</span>
                                                        <span v-if="field.status == 2 && field.transaction_status == 1" class="text-theme-6 font-bold">Received</span>
                                                        <span v-if="field.status == 3 || field.transaction_status == 3" class="text-theme-6 font-bold">Cancelled</span>
                                                    </span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- BEGIN: new Process Modal -->
    <div id="new-process-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <a data-dismiss="modal" href="javascript:;">
                    <XIcon class="w-8 h-8 text-gray-500" />
                </a>
                <div class="modal-body p-10 text-center">
                    <div class="intro-y">
                        <!-- <div id="vertical-form"> -->
                        <div class="flex flex-col justify-between">
                            <!-- BEGIN: Data List -->
                            <div class="intro-y overflow-auto">
                                <div class="flex">
                                </div>
                            </div>
                            <div class="p-5">
                                <div>
                                    <div class="text-left">Transaction Type</div>
                                    <TomSelect v-model="process.transaction_type" :options="{
                          placeholder: 'Select Transaction Type'
                        }" class="w-full">
                                        <option value="0">Process Assign</option>
                                        <option value="1">Manager Transfer</option>
                                        <!-- <option value="2">Job Done</option> -->
                                    </TomSelect>
                                </div>
                                <div class="mt-3" v-if="process.transaction_type == '1'">
                                    <div class="text-left">Select Manager</div>
                                    <TomSelect v-model="process.manager_id" @change="validateManagerId" :options="{
                          placeholder: 'Select Manager'
                        }" class="w-full">
                                        <option value="0">Select Manager</option>
                                        <option v-for="(manager,ind) in page_data.managers" :key="ind" :value="manager.id">{{ manager.first_name + ' ' + manager.last_name }}</option>
                                    </TomSelect>
                                    <!-- <span class="text-primary-3 mb-2" v-if="errors.manager_id">{{ errors.manager_id[0] }}</span> -->
                                </div>
                                <div class="mt-3" v-if="process.transaction_type == '0'">
                                    <div class="text-left">Select Process</div>
                                    <TomSelect v-model="process.process_id" @change="getEmployees(process.process_id)" :options="{
                          placeholder: 'Select Process'
                        }" class="w-full">
                                        <option value="0">Select Process</option>
                                        <option v-for="(process,ind) in page_data.processes" :key="ind" :value="process.id">{{ process.process_name }}</option>
                                    </TomSelect>
                                    <!-- <span class="text-primary-3 mb-2" v-if="errors.process_id">{{ errors.process_id[0] }}</span> -->

                                </div>
                                <div class="flex items-center mt-3" v-if="process.process_id != '0' && process.transaction_type == '0'">
                                    <div class="form-check">
                                        <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="0" type="radio" name="singleinternal" id="singleinternal" v-model="process.eternals">
                                        <label class="form-check-label inline-block text-black text-lg" for="singleinternal">
                                            Internal Employee
                                        </label>
                                    </div>
                                    <div class="form-check ml-6">
                                        <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="singleexternal" id="singleexternal" v-model="process.eternals">
                                        <label class="form-check-label inline-block text-black text-lg" for="singleexternal">
                                            Job Party
                                        </label>
                                    </div>
                                </div>
                                <div class="mt-3" v-if="process.eternals == '0' && process.process_id != '0' && process.transaction_type == '0'">
                                    <div class="text-left">Select Employee</div>
                                    <TomSelect v-model="process.employee_id" :options="{
                            placeholder: 'Select Employee'
                          }" class="w-full">
                                        <option value="0">Select Employee</option>
                                        <option v-for="(emp,ind) in page_data.employees" :key="ind" :value="emp.id">{{ emp.first_name + ' ' + emp.last_name}}</option>
                                    </TomSelect>
                                    <!-- <span class="text-primary-3 mb-2" v-if="errors.employee_id">{{ errors.employee[0] }}</span> -->
                                </div>
                                <div class="mt-3" v-else-if="process.eternals == '1' && process.process_id != '0' && process.transaction_type == '0'">
                                    <div class="text-left">Select Job Party</div>
                                    <TomSelect v-model="process.party_id" :options="{
                        placeholder: 'Select Party'
                      }" class="w-full">
                                        <option value="0">Select Party</option>
                                        <option v-for="(party,ind) in page_data.parties" :key="ind" :value="party.id">{{ party.firm_name }}</option>
                                    </TomSelect>
                                    <!-- <span class="text-primary-3 mb-2" v-if="errors.party_id">{{ errors.party_id[0] }}</span> -->
                                </div>
                            </div>
                            <button type="button" @click="AssignProcess" :disabled="page_data.submit" class="btn btn-primary w-24 m-auto mt-5">Submit
                                <LoadingIcon v-if="page_data.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: new Process Modal -->

</div>
</template>

<script>
import {
    computed,
    defineComponent,
    inject,
    onMounted,
    reactive,
    ref
} from 'vue'
import {
    mapGetters,
    mapActions
} from 'vuex'

import {
    BASE_URL
} from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker';
import {
    helper as $h
} from '@/utils/helper'
import moment from 'moment';
import FileDownload from "js-file-download";
import store from '@/store';
import {
    useRoute
} from 'vue-router';
// const router = useRouter();
// const route = useRoute();

export default defineComponent({
    components: {
        datetime
    },
    setup() {
        const user_details = computed(() => store.state.user.currentUser)
        const route = useRoute()
        const transaction_details = ref(null)
        const transaction_packets = ref()
        const swal = inject('$swal');

        const process = reactive({
            eternals: '0',
            manager_id: '0',
            process_id: '0',
            employee_id: '0',
            party_id: '0',
            transaction_type: '0',
            type: ''
        })

        const page_data = reactive({
            search_type: '',
            addornot: false,
            employees: [],
            processes: [],
            parties: [],
            submit: false,
            managers: [],
            moment: moment
        })

        const get_records = (id) => {
            const params = {
                trans_id: id
            };
            let promise = axios({
                url: "api/process/get_transaction_details",
                method: "POST",
                params: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    transaction_details.value = data.transaction_details;
                    transaction_packets.value = data.transaction_packets;

                    getProcessData()

                    process.eternals = (transaction_details.value.external == 1 ? 1 : 0)
                    process.manager_id = transaction_details.value.manager_id
                    process.process_id = String(transaction_details.value.process_id)

                    // if(transaction_details.value.external == 1) {
                    //   process.party_id = transaction_details.value.employee_id
                    // } else {
                    //   process.employee_id = transaction_details.value.employee_id
                    // }
                    process.transaction_type = (transaction_details.value.transaction_type == 2 ? '0' : (transaction_details.value.transaction_type == 1 ? '1' : '4'));
                    process.type = transaction_details.value.packet_type

                })
                .catch(_error => {

                });
        }
        const editTransactionStatus = (id, user_id, action) => {
            let trans_id = route.params.id;
            let sender_type = '';
            if (user_id == user_details.value.id) {
                sender_type = "sender";
            } else {
                sender_type = "receiver";
            }

            const params = {
                "trans_id": trans_id,
                'sender_type': sender_type,
                'action': action
            };

            swal({
                title: "Are you sure?",
                text: "You want to" + action + "Request !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm & Accept",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                preConfirm: login => {

                    let promise = axios({
                        url: "api/purchase/hold_action_packet_trans",
                        method: "post",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),

                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            if (data.status == "error") {
                                swal("Error!", data.message, "error");
                            } else {
                                get_records(route.params.id)
                                // router.go();
                                // this.$router.push({path: '/packets/transaction'});
                            }
                        })
                        .catch(_error => {
                            return [];
                        });
                },
                allowOutsideClick: () => !swal.isLoading()
            }).then(result => {
                if (result.value) {
                    //swal("Success!", "User Password Updated.", "success");
                }
            });
        }
        const print_jangad = () => {
            let url = BASE_URL + 'jangad' + '?id=' + route.params.id
            window.open(url, '_blank');
        }
        const export_stock = () => {

            const today = new Date();
            const date = today.getDate() + '' + (today.getMonth() + 1) + '' + today.getFullYear();
            // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date;
            let filename = dateTime + "_stock.xlsx";
            let params = {
                id: route.params.id
            };
            let promise = axios({
                url: "api/export/pjc_ex",
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
                responseType: "blob",
            });
            return promise.then(({
                    data
                }) => {
                    // console.warn(data)
                    FileDownload(data, filename);
                })
                .catch(({
                    response
                }) => {
                    Swal.fire({
                        text: "Something went wrong.",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Try again!",
                        heightAuto: false,
                        customClass: {
                            confirmButton: "btn fw-semibold btn-light-danger btn-sm",
                        },
                    }).then(() => {
                        return;
                    });

                });

        }

        const show_model = () => {
          cash('#new-process-modal').modal('show')
        }

        const getProcessData = () => {
            const params = {
                user_id: user_details.value.account_id,
                process_type: window.btoa(transaction_details.value.packet_type)
            }
            axios({
                url: 'api/master/manager_processes',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                page_data.processes = res.data.items
            })
        }


        const getEmployees = () => {
            const params = {
                manager_id: window.btoa(user_details.value.account_id),
                process_id: window.btoa(process.process_id),
                process_type: window.btoa(transaction_details.value.packet_type),
            }
            if (process.process_id != '0') {

                axios({
                    url: 'api/master/get_employees_by_manager_process',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    page_data.employees = res.data.items
                    process.employee_id = '0'

                    if(transaction_details.value.external == null && transaction_details.value.process_id == process.process_id) {
                      process.employee_id = String(transaction_details.value.employee_id)
                    } else {
                      process.employee_id = '0'
                    }
                })
                axios({
                    url: 'api/master/get_jober_details_by_process',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    page_data.parties = res.data.items
                    process.party_id = '0'

                    if(transaction_details.value.external == 1 && transaction_details.value.process_id == process.process_id) {
                      process.party_id = String(transaction_details.value.employee_id)
                    } else {
                      process.party_id = '0'
                    }
                })
            }
        }

        const get_manager_details = () => {
            axios({
                url: 'api/master/dp_managers',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                page_data.managers = res.data.items
            })
        }

        const AssignProcess = () => {
            const params = {
                trans_id: route.params.id,
                externals: window.btoa(process.eternals),
                process_id: process.process_id == '0' ? '' : window.btoa(process.process_id),
                employee_id: process.employee_id == '0' ? '' : window.btoa(process.employee_id),
                manager_id: process.manager_id == '0' ? '' : window.btoa(process.manager_id),
                party_id: process.party_id == '0' ? '' : window.btoa(process.party_id),
                transaction_type: window.btoa(process.transaction_type),
            }
            page_data.submit = true
            axios({
                url: 'api/process/update_jangad_details',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                page_data.message = res.data.success
                page_data.submit = false
                get_records(route.params.id)
                swal("Success!", page_data.message, "success");
                cash('#new-process-modal').modal('hide')
            }).catch((err) => {
                page_data.submit = false
                swal("Warning!", err.response.data.error, "warning");
            })
        }

        const validateManagerId = () => {
            if (user_details.value.account_id == process.manager_id) {
                process.manager_id = '0'
                swal("Warning!", "Self Transfer is Not Allowed !", "warning");
            }
        }

        onMounted(() => {
            get_records(route.params.id)
            get_manager_details()
        })

        return {
            page_data,
            transaction_details,
            transaction_packets,
            user_details,
            process,
            export_stock,
            print_jangad,
            editTransactionStatus,
            getEmployees,
            AssignProcess,
            moment,
            show_model,
            validateManagerId
        }
    }

})
</script>
